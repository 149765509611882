import Badge, { BadgeStyle } from 'src/components/common/Badge';
import { TransactionCategory } from 'src/types/transactions';
import { Transaction } from 'src/types/transactions';
import { HeadCellItem } from 'src/models/tableModels';

export const GpPaymentColumns: HeadCellItem<Transaction>[] = [
  {
    id: 'issued',
    label: 'Issued',
    sortable: true,
    disablePadding: false,
    align: 'center',
    key: 'issued',
  },
  { id: 'lpName', label: 'LP name', sortable: false, disablePadding: false, align: 'center', key: 'lpName' },
  {
    id: 'category',
    label: 'Category',
    sortable: false,
    disablePadding: false,
    align: 'center',
    key: 'category',
    render: (item: Transaction) => {
      return (
        <Badge
          badgeStyle={
            item.category.toUpperCase() === TransactionCategory.CAPITAL_CALL_BUY_IN
              ? BadgeStyle.BLUE
              : BadgeStyle.YELLOW
          }
          text={item.category}
        />
      );
    },
  },
  {
    id: 'amount',
    label: 'Amount',
    sortable: true,
    disablePadding: false,
    align: 'center',
    key: 'amount',
  },
  {
    id: 'transactionId',
    label: 'Transaction ID',
    sortable: false,
    disablePadding: false,
    align: 'center',
    key: 'transactionId',
  },
];
