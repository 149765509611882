import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getPayments } from 'src/services/paymentService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

type UsePaymentsProps = UseQueryOptions & {
  fundId: number;
  status?: string;
  typeStatus?: string;
  transactionStatus?: string;
};

export const usePayments = ({ fundId, status, typeStatus, transactionStatus, ...props }: UsePaymentsProps) => {
  const { showErrorSnackbar } = useSnackbar();

  const queryKey: Array<string | number> = [QueryKey.Payments, fundId];

  if (status) {
    queryKey.push(status);
  }
  if (typeStatus) {
    queryKey.push(typeStatus);
  }
  if (transactionStatus) {
    queryKey.push(transactionStatus);
  }

  const fetchPayments = async () => {
    try {
      const response = await getPayments(status, fundId, typeStatus, transactionStatus);

      return response.data;
    } catch (e) {
      console.log('Use payments error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      throw new Error((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  return useQuery({
    queryKey,
    queryFn: fetchPayments,
    ...props,
  });
};
