import { useFundById } from 'src/hooks/react-query/funds';
import { usePayments } from 'src/hooks/react-query/banking';

type UseBankingFundDetailsProps = {
  fundId: number;
};

export const useBankingFundDetails = ({ fundId }: UseBankingFundDetailsProps) => {
  let data = {};

  const { data: fundData, isLoading: isFundLoading } = useFundById(fundId);

  const { data: approvedPayments, isLoading: isApprovedPaymentsLoading } = usePayments({
    fundId: fundId,
    status: 'Approved',
    enabled: !!fundId,
  });

  const { data: pendingPayments, isLoading: isPendingPaymentsLoading } = usePayments({
    fundId: fundId,
    status: 'Pending',
    enabled: !!fundId,
  });

  const isLoading = isFundLoading || isApprovedPaymentsLoading || isPendingPaymentsLoading;

  if (fundData && !isLoading) {
    data = fundData.fund;
  }

  return {
    data: {
      ...data,
      approvedPaymentsCount: Array.isArray(approvedPayments) ? approvedPayments.length : 0,
      pendingPaymentsCount: Array.isArray(pendingPayments) ? pendingPayments.length : 0,
    },
    isLoading,
  };
};
