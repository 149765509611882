import { Modal, Stack, Typography, Loader } from 'src/components/common';
import { BankAccountCreation } from 'src/components/banking/common';
import { useUpdateBankAccount, useBankAccountDetails } from 'src/hooks/react-query/banking';
import { UpdateBankAccountPayload } from 'src/models/bankingModel';

type EditBankAccountModalProps = {
  bankAccountId: number;
  open: boolean;
  handleClose: () => void;
};

export const EditBankAccountModal = ({ bankAccountId, open, handleClose }: EditBankAccountModalProps) => {
  const { data: bankAccountDetails, isLoading: isBankAccountDetailsLoading } = useBankAccountDetails({
    bankAccountId,
    filter: 'edit',
    enabled: Boolean(bankAccountId),
  });

  const { mutate: updateBankAccount, isLoading: isUpdateLoading } = useUpdateBankAccount({
    bankAccountId,
    handleClose,
  });

  const isLoading = !bankAccountDetails || isBankAccountDetailsLoading;

  return (
    <Modal title="Edit bank account information" open={open} handleClose={handleClose} withGoBackButton>
      <Stack direction="column" spacing={2}>
        <Typography variant="s14_lh22_r" color="#8F8F8F">
          Enter the following details to edit your bank.
        </Typography>

        {isLoading ? (
          <Loader />
        ) : (
          <BankAccountCreation
            onSubmit={updateBankAccount}
            isSubmitting={isUpdateLoading}
            defaultValues={bankAccountDetails as UpdateBankAccountPayload}
          />
        )}
      </Stack>
    </Modal>
  );
};
