import * as yup from 'yup';

const requiredText = 'This is required field';
const routingNumberLengthText = 'The field must contain 8 or 9 digits.';
const accountNumberLengthText = 'The field must contain 17 digits.';

export default yup.object().shape({
  Alias: yup.string().required(requiredText),
  AccountHolderName: yup.string().required(requiredText),
  RoutingNumber: yup.string().required(requiredText).min(8, routingNumberLengthText).max(9, routingNumberLengthText),
  AccountNumber: yup.string().required(requiredText).min(17, accountNumberLengthText).max(17, accountNumberLengthText),
  BeneficiaryName: yup.string().required(requiredText),
  BeneficiaryAddress: yup.string().required(requiredText),
  BeneficiaryBankName: yup.string().required(requiredText),
  BeneficiaryBankAddress: yup.string().required(requiredText),
  BeneficiaryBankIdSwift: yup.string().required(requiredText),
  BranchCode: yup.string().required(requiredText),
  BankAccountType: yup.string().oneOf(['checking', 'savings']).required(requiredText),
});
