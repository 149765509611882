import { MutableRefObject } from 'react';

export const onScrollItems = (direction: 'left' | 'right', scrollRef: MutableRefObject<HTMLDivElement | null>) => {
  if (scrollRef.current) {
    const scrollAmount = 100;
    scrollRef.current.scrollBy({
      left: direction === 'right' ? scrollAmount : -scrollAmount,
      behavior: 'smooth',
    });
  }
};
