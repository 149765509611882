import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getLpPendingPayments, GetLpPendingPaymentsProps } from 'src/services/paymentService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

type UseLpPendingPayments = UseQueryOptions & GetLpPendingPaymentsProps;

export const useLpPendingPayments = ({ userId, page, pageSize, sortBy, sortOrder, ...props }: UseLpPendingPayments) => {
  const { showErrorSnackbar } = useSnackbar();

  const queryKey: Array<string | number> = [QueryKey.LpPayments, userId];

  if (page) {
    queryKey.push(page);
  }
  if (pageSize) {
    queryKey.push(pageSize);
  }
  if (sortBy) {
    queryKey.push(sortBy);
  }
  if (sortOrder) {
    queryKey.push(sortOrder);
  }

  const fetchLpPendingPayments = async () => {
    try {
      const response = await getLpPendingPayments({ userId, page, pageSize, sortBy, sortOrder });

      return response.data?.payments;
    } catch (e) {
      console.log('Use lp pending payments error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      throw new Error((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  return useQuery({
    queryKey,
    queryFn: fetchLpPendingPayments,
    ...props,
  });
};
