import { Modal, Stack, Typography } from 'src/components/common';
import { BankAccountCreation } from 'src/components/banking/common/manage-banking-info/BankAccountCreation';
import { useCreateBankAccount } from 'src/hooks/react-query/banking';

type AddBankAccountModalProps = {
  open: boolean;
  handleClose: () => void;
};

export const AddBankAccountModal = ({ open, handleClose }: AddBankAccountModalProps) => {
  const { mutate: createBankAccount, isLoading: isCreateLoading } = useCreateBankAccount({ handleClose });

  return (
    <Modal title="Add bank account information" open={open} handleClose={handleClose} withGoBackButton>
      <Stack direction="column" spacing={2}>
        <Typography variant="s14_lh22_r" color="#8F8F8F">
          Enter the following details to add your bank.
        </Typography>

        <BankAccountCreation onSubmit={createBankAccount} isSubmitting={isCreateLoading} />
      </Stack>
    </Modal>
  );
};
