import { Stack, Box, Typography } from 'src/components/common';
import { BankAccountCardDataField } from './components/DataField';
import { ReactComponent as ChipIcon } from 'src/assets/images/icons/chip.svg';
import { capitalize } from '@mui/material';
import { ReactComponent as EditIcon } from 'src/assets/images/icons/edit-icon.svg';
import styles from './styles.module.scss';

type BankAccountCardProps = {
  account: { [key: string]: string | number };
  onEditClick?: () => void;
};

export const BankAccountCard = ({ account, onEditClick }: BankAccountCardProps) => {
  return (
    <Stack direction="column" className={styles.bankAccountCardWrapper}>
      <Stack direction="column" alignItems="center" borderBottom="1px solid #F6F5F4" padding={2}>
        <Typography variant="s14_lh21_r" color="#B6B6B6">
          Alias
        </Typography>
        <Typography variant="s14_lh21_r" color="#000000">
          {account.AliasName || '-----'}
        </Typography>
      </Stack>

      <Stack direction="column" borderBottom="1px solid #F6F5F4" padding={2} spacing={1.5}>
        <BankAccountCardDataField label="Account no" value={account.AccountNumber} />

        <BankAccountCardDataField label="Routing no" value={account.RoutingNumber} />

        <BankAccountCardDataField label="BBI (Swift)" value={account.BBI} />

        <BankAccountCardDataField label="Account type" value={capitalize(account.AccountType as string)} />
      </Stack>

      <Stack
        direction="column"
        padding={2}
        sx={{
          background: '#F6F5F4',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BankAccountCardDataField label="Bank" value={account.BankName} />

          <ChipIcon />
        </Stack>
      </Stack>

      <Box onClick={onEditClick} className={styles.bankAccountCardEditIcon}>
        <EditIcon />
      </Box>
    </Stack>
  );
};
