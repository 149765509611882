import { Stack, Typography } from 'src/components/common';

type BankAccountCardDataFieldProps = {
  label: string;
  value: string | number;
};

export const BankAccountCardDataField = ({ label, value }: BankAccountCardDataFieldProps) => {
  return (
    <Stack direction="column">
      <Typography variant="s12_lh18_r" color="#B6B6B6">
        {label}
      </Typography>

      <Typography variant="s14_lh21_r" color="#000000">
        {value || '-----'}
      </Typography>
    </Stack>
  );
};
