import { useAuth } from 'src/context/AuthContext';
import { UserType } from 'src/models/authModels';

export const useUserRole = () => {
  const { userData } = useAuth();

  return {
    isAdmin: userData?.UserType === (1 as UserType),
    isGp: userData?.UserType === (2 as UserType),
    isLp: userData?.UserType === (3 as UserType),
  };
};
