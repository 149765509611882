import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { createContext, useContext, FC, ReactNode } from 'react';
import { LoginPayload, LogoutPayload, TermsUserDetails, UserDetails } from 'src/models/authModels';
import { login as loginRequest, logout as logoutRequest } from 'src/services/loginService';
import { useSnackbar } from './ShackbarContext';
import { LocalStorageService } from 'src/services/LocalStorage';
import { getSlugsWithPrivilege } from 'src/utils/getSlugsWithPrivilege';
import { ApiError } from 'src/types/api';

type AuthContextType = {
  userData: UserDetails | null;
  isSignedIn: boolean;
  login: (payload: LoginPayload) => Promise<TermsUserDetails | undefined>;
  logout: (payload: LogoutPayload, ...cbs: Array<() => void>) => void;
  isLoading: boolean;
  updateUserDetails(user: Partial<UserDetails>): void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [userData, setUserData] = useState<UserDetails | null>(() => {
    const userFromStorage = localStorage.getItem('extrance_user');
    if (userFromStorage) {
      return JSON.parse(userFromStorage);
    }
    return null;
  });

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const { mutateAsync: loginRequestMutation, isLoading } = useMutation({
    mutationFn: (payload: LoginPayload) => loginRequest(payload),
    onSuccess: async ({ data }) => {
      data?.accessToken?.length && showSuccessSnackbar('Login successfully');
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });

  const { mutateAsync: logoutRequestMutation, isLoading: isLogoutLoading } = useMutation({
    mutationFn: (payload: LogoutPayload) => logoutRequest(payload),
    onSuccess: async () => {
      showSuccessSnackbar('Logout successfully');
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });

  const login = async (payload: LoginPayload) => {
    try {
      const response = await loginRequestMutation(payload);

      response?.data?.accessToken?.length &&
        LocalStorageService.setItem('extrance_access_token', response?.data?.accessToken);
      response?.data?.refreshToken?.length &&
        LocalStorageService.setItem('extrance_refresh_token', response?.data?.refreshToken);
      response?.data?.expiresIn?.length &&
        LocalStorageService.setItem('extrance_expires_in', response?.data?.expiresIn);

      const user = {
        ...response?.data?.user,
        permissions: getSlugsWithPrivilege(response?.data?.roles?.privilege ?? []),
        role: response?.data?.roles?.role_details,
        fundPermissions: response?.data?.roles?.['fund_privilege'],
        featureFlags: response?.data?.featureFlags,
      };
      LocalStorageService.setItem('extrance_user', JSON.stringify(user));

      setUserData(user);

      return response.data;
    } catch (e) {
      console.log('login error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  const logout = useCallback(
    async (Location: LogoutPayload) => {
      try {
        queryClient.clear();
        await logoutRequestMutation(Location);
        LocalStorageService.removeItem('extrance_access_token');
        LocalStorageService.removeItem('extrance_refresh_token');
        LocalStorageService.removeItem('extrance_expires_in');
        LocalStorageService.removeItem('extrance_user');
        setUserData(null);
      } catch (e) {
        console.log('logout error', e);
        showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      }
    },
    [logoutRequestMutation, showErrorSnackbar, queryClient],
  );

  const updateUserDetails = (user: UserDetails) => {
    setUserData(user);
  };

  useEffect(() => {
    const callback = () => {
      if (LocalStorageService.getItem('extrance_user') === null) {
        setUserData(null);
      }
    };
    window.addEventListener('localStorageChange', callback);

    return () => {
      window.removeEventListener('localStorageChange', callback);
    };
  }, []);

  const isSignedIn = Boolean(localStorage.getItem('extrance_access_token'));

  return (
    <AuthContext.Provider
      value={{ userData, isSignedIn, login, logout, isLoading: isLoading || isLogoutLoading, updateUserDetails }}
    >
      {children}
    </AuthContext.Provider>
  );
};
