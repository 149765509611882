import { ThemeCssVarOverrides, SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as SelectArrowDown } from 'src/assets/images/icons/select-arrow-down.svg';

export const selectComponentTheme: ThemeCssVarOverrides = {
  MuiSelect: {
    defaultProps: {
      IconComponent: (props: SvgIconProps) => (
        <SvgIcon
          component={SelectArrowDown}
          viewBox="0 0 8 5"
          sx={{ width: 8, height: 5, marginRight: '12px' }}
          {...props}
        />
      ),
    },
    styleOverrides: {
      root: {
        background: '#f6f5f4',
        borderRadius: 8,
        minHeight: '46px',
        border: '1px solid #E1E1E1',

        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
        color: '#000000',

        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
      iconOpen: {
        transform: 'rotate(180deg)',
        transition: 'transform 0.2s',
      },
      icon: {
        transition: 'transform 0.2s',
        top: 'calc(50% - 2.5px)',
      },
    },
  },
};
