import { Box } from '@mui/material';
import Button from '../../../components/common/Button';
import Modal from '../../../components/Modal';
import Dropzone from 'react-dropzone';

import styles from './styles.module.scss';
import { useState } from 'react';
// import Checkbox from '../../../components/common/Checkbox';
import IconDelete from '../../icons/IconDelete';

// const dropzoneRef = createRef();

const ConfirmPaymentSent = ({ isVisible, onCancel, onConfirm }: ConfirmPaymentReceptionProps) => {
  const [
    confirm,
    // setConfirm
  ] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleOnDrop = (files: File[]) => {
    setUploadedFiles(prev => {
      return [...prev, ...files];
    });
  };

  const deleteFile = (fileName: string) => {
    setUploadedFiles(prev => {
      return prev.filter(file => file.name !== fileName);
    });
  };

  const handleConfirm = () => {
    onConfirm(uploadedFiles);
  };

  return (
    <Modal open={isVisible} onClose={onCancel} title="Marking payment as sent" showBack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
        }}
      >
        <p>Please confirm the payment has been done and provide any transfer certificates or files relevant</p>

        <div className={styles.paymentCriteriaInformation}>
          <h3>Correct payment criteria</h3>
          <p>The total amount of this transaction has been transferred to the given account</p>
          <p>This confirmation cannot be rolled back</p>
        </div>

        <div className={styles.paymentCriteriaConfirmation}>
          {/* <Checkbox
            onChange={e => {
              setConfirm(e.target.checked);
            }}
            id="confirm-payment-criteria"
          /> */}
          <label htmlFor="confirm-payment-criteria">My payment follows the correct criteria</label>
        </div>

        <div className={styles.uploadFilesContainer}>
          <Dropzone maxFiles={10} onDrop={handleOnDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-text">Click here to add a file</div>
                <div className="dropzone-subtext">or</div>
                <div className="dropzone-text">Drop it here</div>
              </div>
            )}
          </Dropzone>

          {uploadedFiles.length > 0 && (
            <>
              <p className={styles.uploadedFilesText}>Uploaded files:</p>
              <div className={styles.uploadedFilesList}>
                {uploadedFiles.map((file: File) => (
                  <li>
                    <div className={styles.uploadedFile}>
                      <span>{file.name}</span>
                      <div className={styles.deleteIcon} onClick={() => deleteFile(file.name)}>
                        <IconDelete />
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            </>
          )}
        </div>

        <Box
          sx={{
            display: 'flex',
            marginTop: '32px',
          }}
        >
          <Button
            customStyle={{ flex: 1 }}
            size="small"
            order="primary"
            onClick={handleConfirm}
            variant="primary"
            disabled={!confirm}
          >
            Mark payment as sent
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

interface ConfirmPaymentReceptionProps {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (files: File[]) => void;
}

export default ConfirmPaymentSent;
