import { useNavigate, useParams } from 'react-router-dom';
import ArrowRightIcon from 'src/assets/images/icons/arrow-right.svg';
import { CountBadge } from 'src/components/common/CountBadge';

type BankingActionWidgetPendingApprovalsProps = {
  fundName: string;
  countPayments: number;
};

export const BankingActionWidgetPendingApprovals = ({
  fundName,
  countPayments,
}: BankingActionWidgetPendingApprovalsProps) => {
  const navigate = useNavigate();
  const { fundId } = useParams();

  return (
    <div className="widget-box">
      <div className="widget-main-content">
        <div className="widget-label">New Activity</div>
        <div className="widget-title">Payments pending approval</div>
        <CountBadge text="Approvals pending" noContentText="No pending approvals" count={countPayments} />
      </div>
      <div className="footer-wrapper">
        <div className="separator" />
        <div
          className="cta-wrapper"
          onClick={() => {
            navigate(`/gpbanking/${fundId}/payments-approval`, {
              state: { fund_Name: fundName },
            });
          }}
        >
          <div className="cta-text">Review payments</div>
          <div className="arrow-button-box">
            <img src={ArrowRightIcon} width="12px" height="12px" />
          </div>
        </div>
      </div>
    </div>
  );
};
