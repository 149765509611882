import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BankingActionWidgetManageBanking,
  BankingActionWidgetTransactionHistory,
  BankingActionWidgetPendingPayments,
  BankingActionWidgetPendingApprovals,
} from 'src/components/banking/gp/widgets';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import IconExternalLink from 'src/uiLibrary/icons/IconExternalLink';
import Banner from 'src/components/common/Banner/Banner';
import FboHistoryDisplayWidget from '../fboHistoryDisplayWidget';
import { Fund, FundResponse } from 'src/types/Funds';
import { useFundBankDetails } from 'src/hooks/react-query/banking';
import { Loader } from 'src/components/common';

export type FundViewFund = Fund & {
  approvedPaymentsCount: FundResponse['approvedPaymentsCount'];
  pendingPaymentsCount: FundResponse['pendingPaymentsCount'];
};

type GpBankingFundViewComponentProps = {
  fund: FundViewFund;
};

export const GpBankingFundViewComponent = ({ fund }: GpBankingFundViewComponentProps) => {
  const navigate = useNavigate();

  //TODO: We can add flag isMissingBanningInfo on the backend and use fund.isMissingBanikgInfo instead of this
  const { data: fundBankDetails, isLoading: isFundBankDetailsLoading } = useFundBankDetails({ fundId: fund.id });

  if (!Array.isArray(fundBankDetails) || isFundBankDetailsLoading) {
    return <Loader />;
  }

  const isMissingBanikgInfo = fundBankDetails.length < 2;

  return (
    <div>
      <div className="header-row">
        <div className="page-title-container">
          <div className="back-login" onClick={() => navigate('/gpbanking')}>
            <div className="back-text">
              <IconButton size="large" component="span">
                <IconArrowLeft stroke="#8F8F8F" />
              </IconButton>
              Back
            </div>
          </div>
          <div className="page-title">{fund.name}</div>
        </div>
      </div>
      {isMissingBanikgInfo && (
        <div className="dashboard-row-components">
          <Banner
            title="Banking information is incomplete"
            subtitle="Please provide your banking details and setup your profile to unlock the full capabilities of Extrance"
            variant="error"
            actionComponent={
              <div
                onClick={() => {
                  navigate('manage-banking-info');
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <IconExternalLink />
              </div>
            }
          />
        </div>
      )}
      <div className="banking-actions-row-wrapper">
        <div className="banking-col-wrap">
          <FboHistoryDisplayWidget fund_Name={fund.name} />
        </div>
        <div className="banking-col-wrap">
          <div className="banking-actions-row-wrapper" style={{ flexGrow: 1 }}>
            <BankingActionWidgetPendingPayments countPayments={fund.approvedPaymentsCount} fundName={fund.name} />

            <BankingActionWidgetPendingApprovals countPayments={fund.pendingPaymentsCount} fundName={fund.name} />
          </div>
          <div className="banking-actions-row-wrapper" style={{ flexGrow: 1 }}>
            <BankingActionWidgetTransactionHistory fund_Name={fund.name} />
            <BankingActionWidgetManageBanking
            //TODO: Add account data
            // fundData={{
            //   alias,
            //   accountNumber,
            // }}
            />
          </div>
        </div>
      </div>
      {/* <div className="banking-actions-row-container">
        <BankingActionWidgetPendingPayments
          countPayments={fund.pendingPayments}
          fund_Name={fund?.BankLevelId?.Fund.name}
        />
      </div> */}

      {/* <div className="banking-actions-row-wrapper">
        <BankingActionWidgetPendingApprovals
          countPayments={fund.pendingApprovalPayments}
          fund_Name={fund?.BankLevelId?.Fund.name}
        />
        <BankingActionWidgetTransactionHistory
          fund_Name={fund?.BankLevelId?.Fund.name}
        />
        <BankingActionWidgetManageBanking fund={fund} />
      </div> */}

      {/* <div className="drilldown-section">
        <div className="drilldown-section-header">
          <div className="header-title">{isFund ? "Investments" : "Assets" }</div>
          <div className="header-icons"></div>
        </div>
        <div className="drilldown-items-grid">
          {fund[isFund ? "investments" : "assets"].map((inv: any) => {
            return (
              <DrilldownItem
                name={inv.name}
                assetCount={inv.assetCount}
                defaultAccNo={inv.bankingInfo[0].accountNumber}
                routingNo={inv.bankingInfo[0].routingNumber}
                viewDetail={viewDetail}
                isFund={isFund}
              />
            );
          })}
        </div>
      </div> */}
    </div>
  );
};
