import React from 'react';

const IconBanking = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 20.0001H19C19.2652 20.0001 19.5196 19.8948 19.7071 19.7072C19.8946 19.5197 20 19.2653 20 19.0001V15.0001C20 14.7349 19.8946 14.4806 19.7071 14.293C19.5196 14.1055 19.2652 14.0001 19 14.0001H18V9.00013H19C19.2652 9.00013 19.5196 8.89477 19.7071 8.70723C19.8946 8.5197 20 8.26534 20 8.00013V5.00013C20 4.80664 19.9439 4.61731 19.8385 4.45508C19.7331 4.29284 19.5828 4.16468 19.406 4.08613L10.406 0.0861269C10.2782 0.0293408 10.1399 0 10 0C9.86013 0 9.72182 0.0293408 9.594 0.0861269L0.594 4.08613C0.417181 4.16468 0.266948 4.29284 0.161515 4.45508C0.0560811 4.61731 -2.45488e-05 4.80664 8.05774e-09 5.00013V8.00013C8.05774e-09 8.26534 0.105357 8.5197 0.292893 8.70723C0.48043 8.89477 0.734784 9.00013 1 9.00013H2V14.0001H1C0.734784 14.0001 0.48043 14.1055 0.292893 14.293C0.105357 14.4806 8.05774e-09 14.7349 8.05774e-09 15.0001V19.0001C8.05774e-09 19.2653 0.105357 19.5197 0.292893 19.7072C0.48043 19.8948 0.734784 20.0001 1 20.0001ZM2 5.65013L10 2.09413L18 5.65013V7.00013H2V5.65013ZM16 9.00013V14.0001H13.333V9.00013H16ZM11.333 9.00013V14.0001H8.667V9.00013H11.333ZM6.667 9.00013V14.0001H4V9.00013H6.667ZM2 16.0001H18V18.0001H2V16.0001ZM12 5.00013C12 5.26534 11.8946 5.5197 11.7071 5.70723C11.5196 5.89477 11.2652 6.00013 11 6.00013H9C8.73478 6.00013 8.48043 5.89477 8.29289 5.70723C8.10536 5.5197 8 5.26534 8 5.00013C8 4.73491 8.10536 4.48056 8.29289 4.29302C8.48043 4.10548 8.73478 4.00013 9 4.00013H11C11.2652 4.00013 11.5196 4.10548 11.7071 4.29302C11.8946 4.48056 12 4.73491 12 5.00013Z"
        fill="black"
      />
    </svg>
  );
};

export default IconBanking;
