import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from 'src/hooks/usePermission';
import { Routes } from 'src/consts/routes';

const PublicLayout = () => {
  const { userData, isSignedIn } = useAuth();
  const { isLp } = useUserRole();

  if (!userData || !isSignedIn) {
    return <Outlet />;
  }

  if (userData && isLp) {
    return <Navigate to={Routes.dashboard} replace />;
  }

  return <Navigate to={Routes.dashboard} replace />;
};

export default PublicLayout;
