import { useState, useMemo } from 'react';
import { usePagination } from 'src/hooks/usePagination';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useLpPendingPayments } from 'src/hooks/react-query/banking';
import { getPendingPaymentColumns } from './PendingPaymentsTable.columns';
import { Table } from 'src/components/common/MuiTable/Table';
import { Loader } from 'src/components/common';

type SortOrder = 'asc' | 'desc';

enum SortFields {
  issued = 'issued',
  amount = 'amount',
}

const sortFunctions = {
  issued: (a: string, b: string) => {
    return new Date(a) > new Date(b) ? 1 : -1;
  },
  amount: (a: string, b: string) => {
    return +a - +b;
  },
};

export const PendingPaymentsTable = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const userId = userData?.UserId;

  const { page, pageSize, setPageSize, setActivePage } = usePagination();

  const {
    data: pendingPayments,
    isLoading: isPendingPaymentsLoading,
    // isError: isPendingPaymentsError,
  } = useLpPendingPayments({
    userId: Number(userId),
    enabled: Boolean(userId),
    page: page + 1 ?? 1,
    pageSize,
  });

  const [sortField, setSortField] = useState<SortFields>(SortFields.issued);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const handleSortChange = (sortField: SortFields, sortOrder: SortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const columns = useMemo(() => {
    const payNowOnClick = (paymentId: number) => {
      //TODO: Add pay now functionality
      console.log('Pay now click', paymentId);
    };

    const showDetailsOnClick = (paymentId: number) => {
      navigate(`/banking/payment/${paymentId}/details`);
    };

    return getPendingPaymentColumns({ payNowOnClick, showDetailsOnClick });
  }, [navigate]);

  const sortedPendingPayments = useMemo(() => {
    if (Array.isArray(pendingPayments) && sortField && sortOrder) {
      return pendingPayments.sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        const comparisonResult = sortFunctions[sortField](String(aValue), String(bValue));

        return sortOrder === 'asc' ? comparisonResult : -comparisonResult;
      });
    }
    return [];
  }, [pendingPayments, sortField, sortOrder]);

  if (!Array.isArray(pendingPayments) || isPendingPaymentsLoading) return <Loader />;

  return (
    <Table
      data={sortedPendingPayments}
      columns={columns}
      order={sortOrder}
      handleSortChange={handleSortChange}
      orderBy={sortField}
      pagination={{
        page,
        rowsPerPage: pageSize,
        total: pendingPayments.length,
        onPageChange: setActivePage,
        onPageSizeChange: setPageSize,
      }}
    />
  );
};
