import { useEffect, useState } from 'react';
import {
  ManualPaymentResponse,
  manualPaymentWithReceivedStep,
  manualPaymentTransactionType,
} from '../../models/manualPaymentModels';
import ManualPaymentStatusComponent from './manualPaymentStatusComponent';
import { MANUAL_PAYMENT_STATUS as PAYMENT_STATUS } from '../../models/manualPaymentModels';
import { useParams } from 'react-router-dom';
import { getManualPayment } from '../../services/paymentService';
import { formatDateNoTime } from '../utils';

const mapPayment = (payment: ManualPaymentResponse): manualPaymentTransactionType => {
  const mappedPayment = {
    id: payment.TransactionCode || '',
    amount: Number(payment.TotalAmount) || 0,
    steps: [
      {
        status: PAYMENT_STATUS.PENDING,
        timestamp: payment.PaymentDate && formatDateNoTime(payment.PaymentDate),
        attachments: [],
      },
      {
        status: PAYMENT_STATUS.SENT,
        timestamp: payment.SentDate && formatDateNoTime(payment.SentDate),
        attachments: payment.ReceiptId ? [payment.ReceiptId] : [],
      },
    ],
    category: payment.PaymentCategory || 'capitalCallBuyIn',
    fund: payment.fund?.description || '',
    date: payment.CreationDateTime ? formatDateNoTime(payment.CreationDateTime) : '',
    fundName: payment.fund?.name || '',
    fundId: payment.fund?.id || -1,
    beneficiaryName: payment.Bank?.BeneficiaryName || '',
    deadline: payment.PaymentDate ? formatDateNoTime(payment.PaymentDate) : '',
    beneficiaryBankAddress: payment.Bank?.BeneficiaryBankAddress || '',
    beneficiaryAddress: payment.Bank?.BeneficiaryAddress || '',
    beneficiaryBankName: payment.Bank?.BeneficiaryBankName || '',
    accountNumber: payment.Bank?.AccountNumber || '',
    branchCode: payment.Bank?.BranchCode || '',
    routingNumber: payment.Bank?.RoutingNumber || '',
    beneficiaryBankId: payment.Bank?.BeneficiaryBankIdSwift || '',
    paymentId: payment.id || -1,
    buyerId: payment.Buyer?.userId || -1,
    sellerId: payment.Seller?.userId || -1,
    receiptId: payment.ReceiptId,
  };
  if (manualPaymentWithReceivedStep.findIndex(category => category === payment.PaymentCategory) !== -1) {
    mappedPayment.steps.push({
      status: PAYMENT_STATUS.RECEIVED,
      timestamp: payment.ReceivedDate && formatDateNoTime(payment.ReceivedDate),
      attachments: [],
    });
  }

  return mappedPayment;
};

const ManualPaymentStatusContainer = () => {
  const { id } = useParams();
  const [mappedPayment, setPayment] = useState<manualPaymentTransactionType>();

  useEffect(() => {
    const paymentId = Number(id);
    let payment;
    if (paymentId) {
      getManualPayment(paymentId)
        .then(result => {
          if (result.data?.data?.payment) {
            payment = mapPayment(result.data.data.payment);
            setPayment(payment);
          } else {
            throw new Error('Payment is undefined');
          }
          // eslint-disable-next-line no-console
        })
        .catch(err => console.log(err));
    }
  }, [id]);

  return mappedPayment ? <ManualPaymentStatusComponent transaction={mappedPayment} /> : null;
};

export default ManualPaymentStatusContainer;
