import { ThemeCssVarOverrides } from '@mui/material';

export const tablePaginationComponentTheme: ThemeCssVarOverrides = {
  MuiTablePagination: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      selectLabel: {
        margin: 0,
      },
      displayedRows: {
        margin: 0,
      },
    },
  },
};
