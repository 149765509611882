import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { createBankAccount } from 'src/services/bankingService';
import { ApiError } from 'src/types/api';
import { CreateBankAccountPayload } from 'src/models/bankingModel';
import { QueryKey } from 'src/consts/query';

type UseCreateBankAccountOptions = {
  handleClose?: () => void;
};

export const useCreateBankAccount = ({ handleClose }: UseCreateBankAccountOptions) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (body: CreateBankAccountPayload) => createBankAccount(body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FundBankDetails] });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === QueryKey.BankAccountDetails,
      });

      showSuccessSnackbar('Bank account created');

      if (handleClose) {
        handleClose();
      }
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
