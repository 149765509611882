import React from 'react';

const IconExternalLink = (props: IconEditProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M18.3337 10.4993V17.9993C18.3337 18.2204 18.2459 18.4323 18.0896 18.5886C17.9333 18.7449 17.7213 18.8327 17.5003 18.8327H2.50033C2.27931 18.8327 2.06735 18.7449 1.91107 18.5886C1.75479 18.4323 1.66699 18.2204 1.66699 17.9993V2.99935C1.66699 2.77834 1.75479 2.56637 1.91107 2.41009C2.06735 2.25381 2.27931 2.16602 2.50033 2.16602H10.0003C10.2213 2.16602 10.4333 2.25381 10.5896 2.41009C10.7459 2.56637 10.8337 2.77834 10.8337 2.99935C10.8337 3.22036 10.7459 3.43232 10.5896 3.5886C10.4333 3.74488 10.2213 3.83268 10.0003 3.83268H3.33366V17.166H16.667V10.4993C16.667 10.2783 16.7548 10.0664 16.9111 9.91009C17.0673 9.75381 17.2793 9.66602 17.5003 9.66602C17.7213 9.66602 17.9333 9.75381 18.0896 9.91009C18.2459 10.0664 18.3337 10.2783 18.3337 10.4993V10.4993ZM17.8187 2.23018C17.7178 2.18794 17.6096 2.16613 17.5003 2.16602H13.3337C13.1126 2.16602 12.9007 2.25381 12.7444 2.41009C12.5881 2.56637 12.5003 2.77834 12.5003 2.99935C12.5003 3.22036 12.5881 3.43232 12.7444 3.5886C12.9007 3.74488 13.1126 3.83268 13.3337 3.83268H15.4887L9.41116 9.91018C9.33157 9.98705 9.26808 10.079 9.22441 10.1807C9.18073 10.2823 9.15774 10.3917 9.15678 10.5023C9.15582 10.613 9.17691 10.7227 9.21881 10.8251C9.26071 10.9276 9.32259 11.0206 9.40083 11.0988C9.47907 11.1771 9.57212 11.239 9.67453 11.2809C9.77694 11.3228 9.88668 11.3439 9.99733 11.3429C10.108 11.3419 10.2173 11.3189 10.319 11.2753C10.4207 11.2316 10.5126 11.1681 10.5895 11.0885L16.667 5.01102V7.16602C16.667 7.38703 16.7548 7.59899 16.9111 7.75527C17.0673 7.91155 17.2793 7.99935 17.5003 7.99935C17.7213 7.99935 17.9333 7.91155 18.0896 7.75527C18.2459 7.59899 18.3337 7.38703 18.3337 7.16602V2.99935C18.3331 2.89008 18.3113 2.78196 18.2695 2.68102C18.1849 2.47692 18.0228 2.31476 17.8187 2.23018V2.23018Z"
        fill={props.color ? props.color : '#000000'}
      />
    </svg>
  );
};
export interface IconEditProps {
  color?: string;
}

export default IconExternalLink;
