import { ListItemText, MenuItem } from '@mui/material';
import { Loader } from 'src/components/common/Loader';
import { SelectOption } from 'src/types/custom';
import styles from './styles.module.scss';

//TODO: Need to remove this custom select

interface SelectProps {
  selectItem: SelectOption | null;
  options: SelectOption[];
  isLoading?: boolean;
  onChange(value: SelectOption): void;
}

export const Select = ({ selectItem, options, isLoading = false, onChange }: SelectProps) => {
  const { value: selectedValue } = selectItem || {};

  return (
    <div className={styles['select']}>
      {isLoading ? (
        <Loader />
      ) : (
        options.map(({ label, value }) => (
          <MenuItem key={value} selected={selectedValue === value} onClick={() => onChange({ label, value })}>
            <ListItemText primary={label} />
          </MenuItem>
        ))
      )}
    </div>
  );
};
