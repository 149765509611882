import { useState } from 'react';
import { Stack, Typography, SearchFilterBarContainer, SearchComponent } from 'src/components/common';
import { FundListPreselectionItem } from './fundListPreselection.item';
import { Fund } from 'src/types/Funds';
import { searchByRegex } from 'src/utils/searchByRegex';

type FundsListPreselectionProps = {
  funds: Fund[];
};

export const FundsListPreselection = ({ funds }: FundsListPreselectionProps) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="funds-list-container">
      <Stack direction="row" alignItems="center" justifyContent="space-between" className="list-header">
        <Typography variant="s16_lh24_b" color="#000" whiteSpace="nowrap">
          Select a Fund
        </Typography>
        <SearchFilterBarContainer
          // TODO: Add filter component
          // filterComponent={}
          searchComponent={
            <SearchComponent
              onSearch={newSearchValue => {
                setSearchValue(newSearchValue);
              }}
              initialSearchValue={searchValue}
              placeholder="Search by name"
            />
          }
        />
      </Stack>
      <div className="list-box">
        {funds
          .filter(fund => searchByRegex(fund.name, searchValue))
          .map(fund => {
            return <FundListPreselectionItem key={fund.id} fund={fund} />;
          })}
      </div>
    </div>
  );
};
