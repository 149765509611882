import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from 'src/assets/images/icons/arrow-right.svg';
import { CountBadge } from 'src/components/common/CountBadge';

type BankingActionWidgetPendingPaymentsProps = {
  fundName: string;
  countPayments: number;
};

export const BankingActionWidgetPendingPayments = ({
  fundName,
  countPayments,
}: BankingActionWidgetPendingPaymentsProps) => {
  const navigate = useNavigate();

  return (
    <div className="widget-box">
      <div className="widget-main-content">
        <div className="widget-label">New Activity</div>
        <div className="widget-title">Pending payments</div>
        <CountBadge text="Pending payments" noContentText="No pending payments" count={countPayments} />
      </div>
      <div className="footer-wrapper">
        <div className="separator" />
        <div
          className="cta-wrapper"
          onClick={() => {
            navigate(`pending-payments`, {
              state: { fund_Name: fundName },
            });
          }}
        >
          <div className="cta-text">Review pending payments</div>
          <div className="arrow-button-box">
            <img src={ArrowRightIcon} width="12px" height="12px" />
          </div>
        </div>
      </div>
    </div>
  );
};
