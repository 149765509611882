export enum MANUAL_PAYMENT_STATUS {
  PENDING = 'Pending payment',
  SENT = 'Payment sent',
  RECEIVED = 'Payment received',
}

export const manualPaymentWithReceivedStep: Partial<manualPaymentTransactionCategoryType[]> = [
  'capitalCallBuyIn',
  'capitalCallFunding',
  'positionSellout',
];

export type ManualPaymentStep = {
  status: MANUAL_PAYMENT_STATUS;
  timestamp?: string;
  attachments?: string[];
};

export type manualPaymentTransactionCategoryType =
  | 'capitalCallBuyIn'
  | 'capitalCallFunding'
  | 'positionSellout'
  | 'distributionPayment';

export type manualPaymentTransactionType = {
  id: string;
  amount: number;
  steps: ManualPaymentStep[];
  category: manualPaymentTransactionCategoryType;
  fund: string;
  date: string;
  fundName: string;
  fundId: number;
  beneficiaryName: string;
  deadline: string;
  beneficiaryBankAddress: string;
  beneficiaryAddress: string;
  beneficiaryBankName: string;
  accountNumber: string;
  branchCode: string;
  routingNumber: string;
  beneficiaryBankId: string;
  paymentId: number;
  buyerId?: number;
  sellerId?: number;
  receiptId?: string;
};

export type ManualPaymentResponse = Partial<{
  fund: Fund;
  id: number;
  PaymentType: string;
  PaymentCategory: manualPaymentTransactionCategoryType;
  TransactionCode: string;
  TransactionId: string;
  PaymentId: string;
  TransferId: string;
  ReceiptId: string;
  PaymentDate: string;
  ReceivedDate: string;
  SentDate: string;
  Amount: string;
  ServiceAmount: string;
  ServiceAmountPercentage: string;
  TotalAmount: string;
  PaymentStatus: string;
  TransferStatus: string;
  IsDeleted: number;
  CreationDateTime: string;
  CreationUserId: string;
  LastModificationDateTime: string;
  LastModificationId: string;
  DeletionDateTime: string;
  DeletionUserID: string;
  request: Request;
  Buyer: Buyer;
  Seller: Seller;
  Bank: Bank;
}>;

export type Fund = Partial<{
  id: number;
  fundCode: string;
  name: string;
  Fund_since: string;
  IsRemoved: number;
  currentValue: number;
  investedValue: number;
  description: string;
  Fund_value: string;
  ExternalCustomerId: string;
  ExternalBusinessId: string;
  ExternalMetadata: string;
  HasPaymentsAuto: number;
  HasApprovedOrumAccount: number;
  ExternalBusinessStatus: string;
  IsActive: number;
  CreationDateTime: string;
  Type: string;
  GP_approval_required: number;
}>;

export type Request = Partial<{
  id: number;
  RequestCode: string;
  Stack: string;
  Status: string;
  PaymentStatus: string;
  Type: string;
  RequestDate: string;
  // QuoteFile: any;
  InvestedAmount: string;
  EstimateValue: string;
  RequestedAmount: string;
  AgreedAmount: string;
  GpRequired: number;
  SoftLock: number;
  GpApproveDate: string;
  GpApprovalStatus: number;
  RequestAcceptDate: string;
  AgreementSign: number;
  IsActive: number;
  IsDeleted: number;
  CreationDateTime: string;
  CreationUserId: number;
  LastModificationDateTime: string;
  LastModificationId: number;
  DeletionDateTime: string;
  DeletionUserID: string;
  SellOrderId: string;
  BuyOrderId: string;
  SellOrderStatus: string;
  BuyOrderStatus: string;
  MatchId: string;
}>;

export type Buyer = Partial<{
  userId: number;
  UserName: string;
  roleId: number;
  FirstName: string;
  LastName: string;
  ExternalCustomerId: string;
  CustomerReferenceId: string;
  ExternalPersonStatus: string;
  UserType: number;
  PaymentRequired: number;
  Email: string;
  CompanyName: string;
  CompanyId: number;
  CountryCode: string;
  AccountStatus: string;
  ContactNumber: string;
  Password: string;
  ProfileImage: string;
  DateOfBirth: string;
  Address: string;
  City: string;
  ZipCode: string;
  StateId: number;
  CountryId: number;
  FirstFlowLogin: boolean;
  EmailVerified: boolean;
  MobileVerified: boolean;
  IsBankExist: boolean;
  Subscriptions_id: number;
  CustomSubscriptionsId: string;
  SubscriptionUntil: string;
  SubscriptionFrom: string;
  IsSubscriptionCancelled: number;
  IsCustomSubscribed: number;
  LpCount: string;
  AMU: string;
  CustomSubscribedAmount: string;
  CustomSubscribedAmountUpdate: string;
  IsActive: number;
  Stripe_customer_id: string;
  stripe_account_id: string;
  LastLoginDateTime: string;
  CreationUserId: string;
  CreationDateTime: string;
  LastModificationDateTime: string;
  LastModificationUserId: number;
  EmailNotificationsEnabled: number;
  AgencyName: string;
  DeletionDateTime: string;
  ResetPasswordDateTime: string;
  DeletionUserID: string;
  IsTemporary: boolean;
  NorthAccountSid: string;
}>;

export type Seller = Partial<{
  userId: number;
  UserName: string;
  roleId: number;
  FirstName: string;
  LastName: string;
  ExternalCustomerId: string;
  CustomerReferenceId: string;
  ExternalPersonStatus: string;
  UserType: number;
  PaymentRequired: number;
  Email: string;
  CompanyName: string;
  CompanyId: string;
  CountryCode: string;
  AccountStatus: string;
  ContactNumber: string;
  Password: string;
  ProfileImage: string;
  DateOfBirth: string;
  Address: string;
  City: string;
  ZipCode: string;
  StateId: number;
  CountryId: number;
  FirstFlowLogin: boolean;
  EmailVerified: boolean;
  MobileVerified: boolean;
  IsBankExist: boolean;
  Subscriptions_id: string;
  CustomSubscriptionsId: string;
  SubscriptionUntil: string;
  SubscriptionFrom: string;
  IsSubscriptionCancelled: number;
  IsCustomSubscribed: number;
  LpCount: string;
  AMU: string;
  CustomSubscribedAmount: string;
  CustomSubscribedAmountUpdate: string;
  IsActive: number;
  Stripe_customer_id: string;
  stripe_account_id: string;
  LastLoginDateTime: string;
  CreationUserId: string;
  CreationDateTime: string;
  LastModificationDateTime: string;
  LastModificationUserId: string;
  EmailNotificationsEnabled: number;
  AgencyName: string;
  DeletionDateTime: string;
  ResetPasswordDateTime: string;
  DeletionUserID: string;
  IsTemporary: boolean;
  NorthAccountSid: string;
}>;

export type Bank = Partial<{
  id: number;
  BankName: string;
  AccountHolderName: string;
  AccountNumber: string;
  RoutingNumber: string;
  IsActive: number;
  IsDefault: number;
  IsDeleted: number;
  LastModificationDateTime: string;
  LastModificationId: number;
  DeletionDateTime: string;
  DeletionUserID: number;
  AccountType: string;
  BankAccountType: string;
  AccountLevel: string;
  Alias: string;
  LegalName: string;
  BusinessName: string;
  ExternalAccountId: string;
  AccountReferenceId: string;
  ExternalMetadata: string;
  ExternalAccountStatus: string;
  BeneficiaryName: string;
  BeneficiaryAddress: string;
  BeneficiaryBankName: string;
  BeneficiaryBankAddress: string;
  BeneficiaryBankIdSwift: string;
  TaxId: string;
  TaxType: string;
  BranchCode: string;
}>;
