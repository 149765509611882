import { useQuery } from '@tanstack/react-query';
import { getDocumentsByLevelId } from 'src/services/documentsService';
import { createExpandableDocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { DocumentsLevel } from 'src/types/common';
import { QueryKey } from 'src/consts/query';
import { sortByKeys } from 'src/utils/sortByKeys';
import { ApiError } from 'src/types/api';

export const useDocumentsByLevelID = (level: DocumentsLevel, levelID?: number) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchDocuments = async () => {
    try {
      const response = await getDocumentsByLevelId(level, levelID);
      return response.data;
    } catch (error) {
      showErrorSnackbar((error as AxiosError<ApiError>).response?.data.message);
      console.error(`Fetch ${level} documents error`, error);
    }
  };

  return useQuery({
    queryKey: [QueryKey.Documents, level, levelID],
    queryFn: () => fetchDocuments(),
    select: data => {
      if (data.data) {
        const formattedData = data.data.map((doc: { [key: string]: never }) => {
          return createExpandableDocumentData(doc, level);
        }) as DocumentData[];

        return sortByKeys(formattedData, { keys: 'name', ignoreCase: true });
      }

      return [];
    },
    enabled: !!levelID,
  });
};

export const useFundDocuments = (fundId: number) => {
  return useDocumentsByLevelID('fund', fundId);
};

export const useAssetDocuments = (assetId: number) => {
  return useDocumentsByLevelID('asset', assetId);
};

export const useUnitDocuments = (unitId?: number) => {
  return useDocumentsByLevelID('unit', unitId);
};
