import { usePayments } from 'src/hooks/react-query/banking';
import { Stack, Box } from 'src/components/common';
import { PendingApprovalCard } from '../pendingApprovalCard';
import ArrowRight from 'src/assets/images/icons/arrow-right.svg';
import EmptyStateIllustration from 'src/assets/images/icons/no-fund-selection-empty-state-illustration.png';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { useGpBankingPreselectionContext } from 'src/views/gp-banking/gp-banking-preselection/GpBankingPreselection.context';
import { formatDateNoTime } from 'src/views/utils';

export const PreselectionPreview = () => {
  const navigate = useNavigate();

  const { selectedFundId } = useGpBankingPreselectionContext();

  const { data: pendingPayments, isLoading } = usePayments({
    fundId: selectedFundId!,
    status: 'Pending',
    enabled: !!selectedFundId,
  });

  if (selectedFundId && isLoading) {
    return <Loader />;
  }

  return (
    <div className="preview-container">
      {selectedFundId ? (
        <Stack direction="column" alignItems="center" className="wrapper">
          <div className="preview-header">Payments pending your approval</div>

          {Array.isArray(pendingPayments) && pendingPayments.length ? (
            <Stack direction="column" spacing={3} className="pending-approvals-list">
              {pendingPayments.map(payment => {
                return (
                  <Box
                    key={payment.id}
                    onClick={() => {
                      navigate(`/manual-payment-status/${payment.id}`);
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <PendingApprovalCard
                      issued={formatDateNoTime(payment.CreationDateTime)}
                      type={payment.PaymentType}
                      fundName={payment.fund.name}
                      lpName={payment.Seller?.FirstName ?? ''}
                      paymentDeadline={formatDateNoTime(payment.PaymentDate)}
                      amount={payment.request?.RequestedAmount}
                    />
                  </Box>
                );
              })}
            </Stack>
          ) : (
            <div className="empty-state-list">No pending approvals</div>
          )}
          <div
            className="preview-footer"
            onClick={() => {
              navigate(`/gpbanking/${selectedFundId}/`);
            }}
          >
            <div className="footer-text">View fund details</div>
            <div className="icon-box">
              <img src={ArrowRight} width="16px" height="16px" />
            </div>
          </div>
        </Stack>
      ) : (
        <div className="empty-state-no-selection">
          <div className="illustration-container">
            <img src={EmptyStateIllustration} width="70px" height="auto" />
          </div>
          <div className="no-selection-headline">Select a fund</div>
          <div className="no-selection-subheadline">Your selection will display an overview of the fund</div>
        </div>
      )}
    </div>
  );
};
