import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFundBankDetails } from 'src/services/bankingService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';

type UseFundBankDetailsProps = UseQueryOptions & {
  fundId: number;
};

export const useFundBankDetails = ({ fundId, ...props }: UseFundBankDetailsProps) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchFundBankDetails = async () => {
    try {
      const response = await getFundBankDetails(fundId);

      return response.data?.data;
    } catch (e) {
      console.log('Use FundBankDetails error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      throw new Error((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.FundBankDetails, fundId],
    queryFn: fetchFundBankDetails,
    ...props,
  });
};
