import { Routes } from './routes';

export const ProtectedRoute: Record<
  'dashboard' | 'funds' | 'documents' | 'governance' | 'banking' | 'analytics',
  string
> = {
  dashboard: Routes.dashboard,
  funds: Routes.myFunds,
  documents: Routes.documents,
  governance: Routes.governance,
  banking: Routes.gpBanking,
  analytics: Routes.analytics,
};
