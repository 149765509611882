export type Transaction = {
  id: number;
  issued: string;
  //TODO: Remove it ?
  deadline?: string;
  lpName: string;
  category: TransactionCategory;
  amount: string;
  transactionId: string;
};

//TODO: move to consts
export enum TransactionCategory {
  CAPITAL_CALL_BUY_IN = 'CAP CALL BUY IN',
  CAPITAL_CALL_FUNDING = 'CAP CALL FUNDING',
}
