/* eslint-disable @typescript-eslint/no-unused-vars */
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useModal } from 'src/hooks/useModal';
import { createCapCall } from 'src/services/fundsService';
import { getFboAvailable } from 'src/services/governanceService';
import { useQuery } from '@tanstack/react-query';
import NdaAproveContainer from 'src/uiLibrary/modals/nda-approve/ndaApproveContainer';
import TransactionAproveContainer from 'src/uiLibrary/modals/transaction-approve/TransactionApproveContainer';
import MarketplaceActContainer from 'src/uiLibrary/modals/marketplace-activity/marketplaceActContainer';
import { useState } from 'react';
import { getAgreementRequest } from 'src/services/marketplaceService';
import { getFundAgreementDocuments } from 'src/services/documentsService';
import { QueryKey } from 'src/consts/query';
import { DistributeCapitalModal } from 'src/uiLibrary/modals/DistributeCapitalModal';
import { DeployCapitalCallFormData } from '../../../types/Governance';
import { Modal } from '../../../components/common';
import { useActiveCapCall } from 'src/hooks/react-query/governance';
import { Loader } from 'src/components/common/Loader';
import { DeployCapitalCallModalComponent } from 'src/uiLibrary/modals/deployCapitalCall/deployCapitalCallComponent';

enum GovernanceDialog {
  CapCall,
  DistributeCapital,
  MPA,
  Transaction,
  Nda,
}

interface GovernanceActionsProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fund: any;
}

// TODO: refactore component
export const GovernanceActions = ({ fund }: GovernanceActionsProps) => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal<GovernanceDialog>();
  const [isFboFundingOpen, setIsFboFundingOpen] = useState(false);

  const { data: activeCapCall, isLoading: activeCapCallLoading } = useActiveCapCall(fund.id);

  const {
    data: fboBalance,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.FboBalance, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getFboAvailable(fund.id);
        return response.data[0]?.Value;
      }
      return 0;
    },
  });

  const {
    data: counterSign,
    // isLoading,
    // isError,
  } = useQuery({
    queryKey: [QueryKey.CounterSignStatus, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getAgreementRequest(fund.id);
        return response.data;
      }
      return null;
    },
  });

  const {
    data: agreementDocs,
    // isLoading,
    // isError,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.AgreementDocs, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getFundAgreementDocuments(fund.id);
        return response.data;
      }
      return null;
    },
  });

  const openDeployCapCallModal = () => {
    handleOpenModal(GovernanceDialog.CapCall);
  };
  const openDistributeCapitalModal = () => {
    handleOpenModal(GovernanceDialog.DistributeCapital);
  };
  const openMPAModal = () => {
    handleOpenModal(GovernanceDialog.MPA);
  };
  const openTransactionModal = () => {
    handleOpenModal(GovernanceDialog.Transaction);
  };
  const openNdaModal = () => {
    handleOpenModal(GovernanceDialog.Nda);
  };

  const handleDeployCapitalCallOnSubmit = (formData: DeployCapitalCallFormData) => {
    createCapCall(formData);
    // handle On Submit
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDistributeCapitalOnSubmit = (formData: DeployCapitalCallFormData) => {
    // handle On Submit
  };

  if (activeCapCallLoading) return <Loader />;

  return (
    <>
      <List className="actions-box">
        <h3>Governance actions</h3>
        <ListItem button className="list-item" disabled={!!activeCapCall?.IsActive} onClick={openDeployCapCallModal}>
          <ListItemText className="item-text">Deploy capital call</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openDistributeCapitalModal}>
          <ListItemText className="item-text">Distribute capital</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openMPAModal}>
          <ListItemText className="item-text">Marketplace activity</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openTransactionModal}>
          <ListItemText className="item-text">Approve transactions</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openNdaModal}>
          <ListItemText className="item-text"> Approve NDA’s </ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
      </List>
      {
        //TODO: refactore each modal
      }
      {/* <Dialog
        open={isOpen(GovernanceDialog.CapCall)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.DistributeCapital)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.MPA)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.Nda)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.Transaction)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog> */}
      <NdaAproveContainer
        closeModal={handleCloseModal}
        loadNdaData={refetch}
        isVisible={isOpen(GovernanceDialog.Nda)}
        agreementDocs={agreementDocs}
        fund={fund}
      />
      <TransactionAproveContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.Transaction)}
        fund={fund}
      />
      <MarketplaceActContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.MPA)}
        fund={fund}
        countersignTableData={counterSign}
      />

      <DistributeCapitalModal
        open={isOpen(GovernanceDialog.DistributeCapital)}
        onClose={handleCloseModal}
        fundData={{
          id: fund.id,
          name: fund.name,
          code: fund.fundCode,
        }}
      />

      <Modal
        open={isOpen(GovernanceDialog.CapCall)}
        handleClose={handleCloseModal}
        className="dialog-no-padding"
        maxWidth="sm"
      >
        <DeployCapitalCallModalComponent
          fundId={fund.id}
          closeModal={handleCloseModal}
          onSubmit={handleDeployCapitalCallOnSubmit}
        />
      </Modal>
    </>
  );
};
