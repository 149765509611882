import attachmentClip from '../../assets/images/icons/attachment-clip.svg';

import styles from './styles.module.scss';

const AttachmentIndicator = ({ count, onClick }: { count: number; onClick: () => void }) => {
  return (
    <div className={styles.attachmentContainer} onClick={onClick}>
      <div className={styles.attachmentText}>{count + (count !== 1 ? ' Attachments' : ' Attachment')}</div>
      <div className={styles.attachmentBox}>
        <div>
          <img src={attachmentClip} width="16px" height="16px" alt="clip icon" />
        </div>
        <div className={styles.boxIndicator}>{count}</div>
      </div>
    </div>
  );
};

export default AttachmentIndicator;
