import React, { useState } from 'react';
import { Box, FormControl, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SearchForm } from 'src/models/tableModels';
import { Controller, useForm } from 'react-hook-form';
import { Clear } from '@mui/icons-material';

export const SearchComponent = ({ onSearch, initialSearchValue, placeholder }: SearchComponentProps) => {
  const [showSearchField, setShowSearchField] = useState(false);

  const { control, handleSubmit, setValue } = useForm<SearchForm>({
    defaultValues: {
      search: initialSearchValue ? initialSearchValue : '',
    },
  });

  const onSubmit = (payload: SearchForm) => {
    onSearch(payload.search);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickClear = () => {
    setValue('search', '');
    onSearch('');
    setShowSearchField(false);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
      <Grid item marginRight={showSearchField ? 1 : 0}>
        <IconButton
          onClick={() => setShowSearchField(showSearchField => !showSearchField)}
          edge="end"
          type="submit"
          data-testid="search-icon-btn"
          disabled={showSearchField}
        >
          <SearchIcon />
        </IconButton>
      </Grid>

      <Grid item>
        {showSearchField && (
          <Box sx={{ flex: '1 1 100%' }} className="table-search-input">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" className="formControl">
                    <TextField
                      {...field}
                      data-testid="search-input"
                      variant="outlined"
                      margin="normal"
                      placeholder={placeholder}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear search box"
                              onClick={handleClickClear}
                              onMouseDown={handleMouseDown}
                              edge="end"
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                )}
              />
            </form>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
interface SearchComponentProps {
  onSearch: (searchString: string) => void;
  initialSearchValue?: string;
  placeholder?: string;
}
