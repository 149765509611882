import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from 'src/assets/images/icons/arrow-right.svg';

type BankingActionWidgetManageBankingData = {
  alias: string;
  accountNumber: number;
};

type BankingActionWidgetManageBankingProps = {
  fundData?: BankingActionWidgetManageBankingData;
};

export const BankingActionWidgetManageBanking = ({ fundData }: BankingActionWidgetManageBankingProps) => {
  const navigate = useNavigate();
  return (
    <div className="widget-box dark-widget">
      <div className="widget-main-content">
        {/* <div className="widget-label">New Activity</div> */}
        <div className="widget-title">Manage banking information</div>
        <div className="widget-subtitle">
          Manage institutional bank accounts and select the default account for banking operations.
        </div>
        <div className="widget-data-preview">
          <div className="data-preview-label">Account selected as default</div>
          <div className="data-row">
            <div className="data-row-item">
              <div className="row-item-label">Alias</div>
              <div className="row-item-value">{fundData?.alias ?? 'NaN'}</div>
            </div>
            <div className="data-row-item">
              <div className="row-item-label">Account no</div>
              <div className="row-item-value">{fundData?.accountNumber ?? 'NaN'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="separator" />
        <div className="cta-wrapper" onClick={() => navigate('manage-banking-info')}>
          <div className="cta-text">Go to banking info management</div>
          <div className="arrow-button-box">
            <img src={ArrowRightIcon} width="12px" height="12px" />
          </div>
        </div>
      </div>
    </div>
  );
};
