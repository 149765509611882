export enum Permission {
  Dashboard = 'dashboard',
  MyFunds = 'funds',
  Documents = 'documents',
  CustomFolderManagement = 'custom-folder-management',
  DocumentsAdd = 'documents-add',
  DocumentsDelete = 'documents-delete',
  DocumentsEdit = 'documents-edit',
  Governance = 'governance',
  Analytics = 'analytics',
  Banking = 'banking',
  Marteplace = 'marteplace',
}
