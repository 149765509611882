import React from 'react';
import { useRef } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import downloadIcon from '../../assets/images/icons/download.svg';
import styles from './styles.module.scss';

import PaymentBankingSummaryPDF from './paymentBankingSummaryPDF';
import { renderCurrency } from '../../views/utils';
import { manualPaymentTransactionType } from 'src/models/manualPaymentModels';

interface PaymentBankingSummaryWidgetProps {
  receiverInfo: manualPaymentTransactionType;
}

const PaymentBankingSummaryWidget = ({ receiverInfo }: PaymentBankingSummaryWidgetProps) => {
  const bankingDataRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.paymentBankingSummaryWidget} ref={bankingDataRef}>
      <div className={styles.widgetHeaderWrapper}>
        <div className={styles.widgetTitle}>Summary</div>
        <div className={styles.widgetSubtitle}>Payment breakdown and reciever info</div>
      </div>

      <div className={styles.summaryConceptContainer}>
        <div className={styles.conceptDescriptionWrapper}>
          <div className={styles.conceptLabel}>FUND</div>
          <div className={styles.conceptTitle}>{receiverInfo.fundName}</div>
          <div className={styles.conceptSubtitle}>{receiverInfo.fundId}</div>
        </div>
        <div className={styles.conceptAmount}>{renderCurrency(receiverInfo.amount)}</div>
      </div>

      <div className={styles.separator} />

      <div className={styles.widgetRowWrapper}>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Beneficiary name</div>
          <div className={styles.itemValue}>{receiverInfo.beneficiaryName}</div>
        </div>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Deadline</div>
          <div className={styles.itemValue}>{receiverInfo.deadline}</div>
        </div>
      </div>
      <div className={styles.widgetRowWrapper}>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Beneficiary bank address</div>
          <div className={styles.itemValue}>{receiverInfo.beneficiaryBankAddress}</div>
        </div>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Beneficiary address</div>
          <div className={styles.itemValue}>{receiverInfo.beneficiaryAddress}</div>
        </div>
      </div>
      <div className={styles.widgetRowWrapper}>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Beneficiary bank name</div>
          <div className={styles.itemValue}>{receiverInfo.beneficiaryBankName}</div>
        </div>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Account number</div>
          <div className={styles.itemValue}>{receiverInfo.accountNumber}</div>
        </div>
      </div>
      <div className={styles.widgetRowWrapper}>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Branch code</div>
          <div className={styles.itemValue}>{receiverInfo.branchCode}</div>
        </div>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Routing number</div>
          <div className={styles.itemValue}>{receiverInfo.routingNumber}</div>
        </div>
      </div>
      <div className={styles.widgetRowWrapper}>
        <div className={styles.widgetItem}>
          <div className={styles.itemLabel}>Beneficiary bank id (Swift)</div>
          <div className={styles.itemValue}>{receiverInfo.beneficiaryBankId}</div>
        </div>
        <div className={styles.widgetItem}>
          <PDFDownloadLink
            className={styles.downloadButton}
            document={
              <PaymentBankingSummaryPDF
                fundName={receiverInfo.fundName}
                fundId={receiverInfo.fundId}
                amount={receiverInfo.amount}
                beneficiaryName={receiverInfo.beneficiaryName}
                deadline={receiverInfo.deadline}
                beneficiaryBankAddress={receiverInfo.beneficiaryBankAddress}
                beneficiaryAddress={receiverInfo.beneficiaryAddress}
                beneficiaryBankName={receiverInfo.beneficiaryBankName}
                accountNumber={receiverInfo.accountNumber}
                branchCode={receiverInfo.branchCode}
                routingNumber={receiverInfo.routingNumber}
                beneficiaryBankId={receiverInfo.beneficiaryBankId}
              />
            }
            fileName="Payment Summary.pdf"
          >
            <img src={downloadIcon} width="16px" height="16px" style={{ marginRight: 4 }} alt="download icon" />
            Download
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

export default PaymentBankingSummaryWidget;
