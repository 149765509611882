import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFundBankDetails } from 'src/hooks/react-query/banking';
import { useFundById } from 'src/hooks/react-query/funds';
import { Stack, Typography, ArrowGoBackButton, Loader, Skeleton } from 'src/components/common';
import AddCircleIcon from 'src/assets/images/icons/add-circle.svg';
import Button from 'src/components/common/Button';
import { AddBankAccountModal, EditBankAccountModal } from 'src/views/gp-banking/components/bank-account';
import { BankAccountCard } from 'src/components/banking/common/manage-banking-info';

enum Modals {
  AddBankAccount = 'add_bank_account',
  EditBankAccount = 'edit_bank_account',
}

export const GpBankingInfoManagementComponent = () => {
  const navigate = useNavigate();
  const { fundId } = useParams();

  const { data: fundData, isLoading: isFundLoading } = useFundById(Number(fundId));
  const { data: bankDetails, isLoading: isBankDetailsLoading } = useFundBankDetails({ fundId: Number(fundId) });

  const [activeModal, setActiveModal] = useState<Modals | null>();
  const bankAccountId = useRef(null);

  const closeModal = () => {
    setActiveModal(null);
    bankAccountId.current = null;
  };

  if (!Array.isArray(bankDetails) || isBankDetailsLoading || !fundData || isFundLoading) {
    return <Loader />;
  }

  const isMissingBankingData = bankDetails.length < 2;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="column">
          <ArrowGoBackButton onClick={() => navigate(`/gpbanking/${fundId}`)} />

          <Typography variant="s20_lh32_b" color="#000000">
            {fundData.fund.name}
          </Typography>
          <Typography variant="s14_lh22_r" color="#8F8F8F">
            Banking information management
          </Typography>
        </Stack>

        {isMissingBankingData && (
          <Button
            order="secondary"
            size="small"
            customStyle={{ height: 'fit-content', paddingLeft: 16, gap: 8 }}
            onClick={() => {
              setActiveModal(Modals.AddBankAccount);
            }}
          >
            <img src={AddCircleIcon} width="16px" height="16px" />
            Add bank account
          </Button>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={3}
        padding={3}
        borderRadius={2}
        sx={{
          background: '#f6f5f4',
        }}
      >
        {bankDetails.map(account => {
          return (
            <BankAccountCard
              key={account.id}
              account={account}
              onEditClick={() => {
                bankAccountId.current = account.id;
                setActiveModal(Modals.EditBankAccount);
              }}
            />
          );
        })}

        {Array.from(Array(2 - bankDetails.length).keys()).map(i => (
          <Skeleton key={i} height={372} width={240} variant="rectangular" sx={{ borderRadius: '16px' }} />
        ))}
      </Stack>

      <AddBankAccountModal open={activeModal === Modals.AddBankAccount} handleClose={closeModal} />
      <EditBankAccountModal
        bankAccountId={bankAccountId.current!}
        open={activeModal === Modals.EditBankAccount}
        handleClose={closeModal}
      />
    </Stack>
  );
};
