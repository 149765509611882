import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentsAssetDetailComponent from './documentsAssetDetailComponent';
import { useFundById } from 'src/hooks/react-query/funds';
import { useAssetDocuments, useDocumentFolders, useDeleteDocumentMutation } from 'src/hooks/react-query/documents';
import { useAssetDetailsById } from 'src/hooks/react-query/partnership';
import { Loader } from 'src/components/common/Loader';
import ConfirmDialog from 'src/uiLibrary/modals/confirm-modal/confirmDialogContainer';
import { CreateNewFolderModal } from './components/CreateNewFolderModal';
import { UpdateFolderModal } from './components/UpdateFolderModal';
import { useUnitsByAssetId } from 'src/hooks/react-query/unit';

type ConfirmDialogState = {
  id?: number;
  name?: string;
  open: boolean;
};

type UpdateFolderModalState = {
  open: boolean;
  folderId?: number;
  oldFolderName?: string;
};

const DocumentsAssetDetailContainer = () => {
  const params = useParams();
  const fundId = Number(params.fundId);
  const assetId = Number(params.assetId);

  const { data: fundData, isLoading: isFundLoading } = useFundById(fundId);
  const { data: documents, isLoading: isDocumentsLoading } = useAssetDocuments(assetId);
  const { folderList, isLoading: isDocumentsFoldersLoading } = useDocumentFolders(assetId, 'asset');
  const { data: assetDetails, isLoading: isAssetDeailsLoading } = useAssetDetailsById(assetId);
  const { deleteDocument: deleteAssetDocument } = useDeleteDocumentMutation(assetId);
  const { data: units, isLoading: isUnitsLoading } = useUnitsByAssetId(String(assetId));

  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogState>({
    open: false,
  });

  const [createFolderModalData, setCreateFolderModalData] = useState({
    open: false,
  });

  const [updateFolderModalData, setUpdateFolderModalData] = useState<UpdateFolderModalState>({
    open: false,
  });

  const handleDeleteOnClick = async (documentId: number, documentName: string) => {
    setConfirmDialogData({ id: documentId, name: documentName, open: true });
  };

  const closeConfirmDialog = () => {
    setConfirmDialogData({
      open: false,
    });
  };

  const openCreateFolderModal = () => {
    setCreateFolderModalData({ open: true });
  };

  const openUpdateFolderModal = (folderId: number, oldFolderName: string) => {
    setUpdateFolderModalData({ open: true, folderId, oldFolderName });
  };

  const deleteDocument = async (documentId?: number) => {
    if (!documentId) return;

    await deleteAssetDocument(documentId);
    await closeConfirmDialog();
  };

  if (
    !documents ||
    !folderList ||
    !assetDetails ||
    !fundData ||
    isDocumentsLoading ||
    isDocumentsFoldersLoading ||
    isUnitsLoading ||
    isAssetDeailsLoading ||
    isFundLoading
  )
    return <Loader />;

  return (
    <>
      <ConfirmDialog
        isVisible={confirmDialogData.open}
        title={`Are you sure you want to delete the ${confirmDialogData.name ? confirmDialogData.name : 'document'} ?`}
        dialogContentText="You will not be able to restore the file."
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        confirmModal={() => {
          deleteDocument(confirmDialogData.id);
        }}
        closeModal={closeConfirmDialog}
      />

      <CreateNewFolderModal
        open={createFolderModalData.open}
        onClose={() => {
          setCreateFolderModalData({ open: false });
        }}
        assetId={assetId}
      />
      <UpdateFolderModal
        open={updateFolderModalData.open}
        onClose={() => {
          setUpdateFolderModalData({ open: false });
        }}
        assetId={assetId}
        folderId={updateFolderModalData.folderId}
        oldFolderName={updateFolderModalData.oldFolderName}
      />
      <DocumentsAssetDetailComponent
        fund={{
          id: fundData.fund.id,
          name: fundData.fund.name,
        }}
        asset={assetDetails}
        documents={documents}
        folders={folderList}
        unitId={units?.at(0)?.id ?? null}
        handleDeleteOnClick={handleDeleteOnClick}
        openCreateFolderModal={openCreateFolderModal}
        openUpdateFolderModal={openUpdateFolderModal}
      />
    </>
  );
};

export default DocumentsAssetDetailContainer;
