import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from './usePermission';
import { Permission } from 'src/consts/permissions';

type UseAuthorizedProps = {
  permissions?: string[];
  mode?: 'every' | 'some';
  allowForLp?: boolean;
};

export const useProtected = ({ permissions, mode = 'every', allowForLp }: UseAuthorizedProps) => {
  const { userData } = useAuth();
  const { isAdmin, isGp, isLp } = useUserRole();

  if (!userData) {
    return false;
  }

  if (isAdmin) {
    const hasPermissions = permissions?.some(permission => userData.featureFlags?.includes(permission as Permission));
    return hasPermissions;
  }

  if (isLp) {
    return allowForLp;
  }

  if (isGp) {
    const hasLicenceKeyAndPermissions =
      permissions?.some(permission => userData.featureFlags?.includes(permission as Permission)) &&
      permissions?.[mode](permission => userData.permissions.includes(permission));

    return hasLicenceKeyAndPermissions;
  }

  return false;
};
