import { FieldValues } from 'react-hook-form';
import {
  CreateBankAccountPayload,
  CreateCreditCardPayload,
  UpdateBankAccountPayload,
  UpdateCreditCardPayload,
  BankCardDetailsPayload,
  BankAccountPayload,
} from '../models/bankingModel';
import { UseBanksProps } from 'src/hooks/react-query/banking/banks/useBanks';
import { AddBankAccountPayload } from '../models/gpBankingModel';
import ServiceClient from './serviceClientConfig';
import { validateError } from 'src/utils/validateError';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

/* Old endpoints - to be deleted */
/* ****************************** */
/* ****************************** */
export const getBankDetailsList = (limit: number, page: number) => {
  return serviceClient.get('banks?limit=' + limit + '&page=' + page);
};

export const getBankAccountDetailsOld = (accountId: string | undefined) => {
  return serviceClient.get('banks/' + accountId);
};

export const getCardDetails = async (id: number) => {
  try {
    const response = await serviceClient.get(`cards/${id}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getCardDetails error: ', error);
    throw await validateError(error);
  }
};

export const makeDefaultAccount = (accountId: string | undefined) => {
  return serviceClient.put('banks/bank-default/' + accountId, {});
};

// export const defaultCard = (id: number, data: any) => {
//   return serviceClient.put(`cards/card-default/${id}`, data);
// };
export const deleteCard = (id: number) => {
  return serviceClient.delete(`cards/${id}`);
};

/* ****************************** */
/* ****************************** */

// New endpoints
export const getBankAccounts = async ({ limit = 1000 }: UseBanksProps): Promise<{ data: BankAccountPayload[] }> => {
  try {
    const response = await serviceClient.get(`banks?limit=${limit}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getBankAccounts error: ', error);
    throw await validateError(error);
  }
};

export const createBankAccount = (payload: CreateBankAccountPayload) => {
  return serviceClient.post('banks', payload);
};

export const updateBankAccount = (id: number, payload: UpdateBankAccountPayload) => {
  return serviceClient.put(`banks/account/${id}`, payload);
};

export const getSponsorBankAccount = async () => {
  return serviceClient.get('banks?limit=1');
  // try {
  //   //TODO: change serviceClient code
  //   const response = await serviceClient.get('banks?limit=1', {
  //     ...defaultConfigOptions,
  //   });
  //   //TODO: add type from codegen
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   return response.data as any;
  // } catch (error) {
  //   console.error('getSponsorBankAccount error: ', error);
  //   throw await validateError(error);
  // }
};

// TODO: check type
export const getCreditCards = async (): Promise<{ data: BankCardDetailsPayload[] }> => {
  try {
    const response = await serviceClient.get('cards?limit=1000');
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getCreditCards error: ', error);
    throw await validateError(error);
  }
};

export const getBankAccountDetails = (id: number) => serviceClient.get(`banks/${id}`);
export const getCreditCardDetails = (id: number) => serviceClient.get(`cards/${id}`);

export const markBankAccountAsDefault = (id: number, payload: CreateBankAccountPayload) =>
  serviceClient.put(`banks/bank-default/${id}`, payload);

export const markCreditCardAsDefault = async (id: number, payload: UpdateCreditCardPayload) => {
  try {
    const response = await serviceClient.put(`cards/card-default/${id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('markCreditCardAsDefault error: ', error);
    throw await validateError(error);
  }
};

export const updateBankAccountDetails = (id: number, payload: AddBankAccountPayload) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  //.AccountHolderName = "AccountHolderName";
  payload.ConfirmAccountNumber = payload.AccountNumber;
  return serviceClient.put(`banks/${id}`, payload);
};
export const updateCreditCardDetails = (id: number, data: UpdateCreditCardPayload) =>
  serviceClient.put(`cards/${id}`, data);

export const deleteCreditCard = async (id: number) => {
  try {
    const response = await serviceClient.delete(`cards/${id}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('deleteCreditCard error: ', error);
    throw await validateError(error);
  }
};

export const deleteBankAccount = async (accountId: number) => {
  try {
    const response = await serviceClient.delete(`banks/${accountId}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('deleteBankAccount error: ', error);
    throw await validateError(error);
  }
};

export const getBusinessBank = () => serviceClient.get(`banks/business/list`);
export const getFundBank = () => serviceClient.get(`banks/fund/list`);
export const getFundBankDetails = (levelId: number) =>
  serviceClient.get(`banks/business/list?id=${levelId}&level=Fund&verified=true`);
export const putDefaultFundBank = (id: number, fundId?: number) =>
  serviceClient.put(`banks/bank-default/${id}${fundId ? '?fundId=' + fundId : ''}`, {});
export const getFundBusiness = (fundId: number) => serviceClient.get(`banks/business/fund/${fundId}`);

export const getEntityInfo = async () => {
  try {
    const response = await serviceClient.get('banks/entity');
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getEntityInfo error: ', error);
    throw await validateError(error);
  }
};

export const postEntityBusiness = (payload: FieldValues) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  return serviceClient.post('banks/business', payload);
};
export const postEntityPerson = (payload: FieldValues) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  return serviceClient.post('banks/person', payload);
};
export const putEntityPerson = (payload: FieldValues) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  return serviceClient.put('banks/person', payload);
};
export const putEntityBusiness = (payload: FieldValues) => {
  // cleaning unused fieldss
  Object.keys(payload).forEach(key => {
    const keyName = key as keyof typeof payload;
    if (payload[keyName] === '' || payload[keyName] === undefined) {
      delete payload[keyName];
    }
  });
  return serviceClient.put('banks/business', payload);
};
export const getSponsorAccInfo = (id: number) => {
  return serviceClient.get(`banks/fund/sponsor/${id}`);
};

export const addCreditCard = async (payload: CreateCreditCardPayload) => {
  try {
    const response = await serviceClient.post('cards/front/card-create', {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('addCreditCard error: ', error);
    throw await validateError(error);
  }
};

// export const addCardDetails = (data: any) =>
//   serviceClient.post("cards/front/card-create", data);
// export const getCards = (
//   page: number,
//   limit: number,
//   orderField: string,
//   orderSort: string
// ) => {
//   return serviceClient.get(
//     `cards?page=${page}&limit=${limit}&order_field=${orderField}&order_sort=${orderSort}`
//   );
// };
