import { DocType, DurationType } from 'src/models/documentsModels';
import UploadDocumentComponent from './uploadDocumentComponent';
import { UploadDocumentForm } from './validations';

interface UploadDocumentContainerProps {
  closeModal: () => void;
  level: 'fund' | 'asset' | 'unit';
  folderName?: string;
  handleOnSubmit: (formData: FormData) => void;
  id: string | undefined;
  dataProcessingRequires?: boolean;
}

type ModifiedFormData = {
  [key: string]: string | number | Blob;
};

export const UploadDocumentContainer = ({
  level,
  folderName,
  handleOnSubmit,
  closeModal,
  id,
  dataProcessingRequires = false,
}: UploadDocumentContainerProps) => {
  const docType = DocType.FIPFile;
  const freq = DurationType.MONTHLY;

  const onSubmit = (formData: UploadDocumentForm) => {
    const modifiedFormData: ModifiedFormData = {
      Title: formData.Title,
      DurationType: formData.DurationType,
      DocType: formData.DocType,
      DocDate: formData.DocDate,
      IsActive: formData.IsActive,
      DocLink: formData.DocLink,
      IsLpVisible: formData.IsLpVisible ? 1 : 0,
      DataProcessingRequires: formData.DataProcessingRequires ? 1 : 0,
    };

    if (formData.FolderId !== 'none') {
      modifiedFormData.FolderId = formData.FolderId;
    }

    const formDataToBeSent = new FormData();

    for (const key in modifiedFormData) {
      const value = modifiedFormData[key];

      if (typeof value === 'string' || value instanceof Blob) {
        formDataToBeSent.append(key, value);
      } else if (typeof value === 'number') {
        formDataToBeSent.append(key, value.toString());
      }
    }

    handleOnSubmit(formDataToBeSent);
  };

  return (
    <UploadDocumentComponent
      closeModal={closeModal}
      onSubmit={onSubmit}
      type={docType}
      freq={freq}
      level={level}
      folderName={folderName}
      id={id}
      dataProcessingRequires={dataProcessingRequires}
    />
  );
};
