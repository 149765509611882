import { usePayments } from 'src/hooks/react-query/banking';
import { Stack, Box, Loader, CountBadge } from 'src/components/common';
import { useGpBankingPreselectionContext } from 'src/views/gp-banking/gp-banking-preselection/GpBankingPreselection.context';
import { Fund } from 'src/types/Funds';

type FundListPreselectionItemProps = {
  fund: Fund;
};

export const FundListPreselectionItem = ({ fund }: FundListPreselectionItemProps) => {
  const { selectedFundId, setSelectedFundId } = useGpBankingPreselectionContext();

  const { data: pendingPayments, isLoading: isPaymentsLoading } = usePayments({
    fundId: fund.id,
    status: 'Pending',
  });

  if (!pendingPayments || isPaymentsLoading) {
    return <Loader />;
  }

  const isSelected = selectedFundId === fund.id;
  const isError = fund.isMissingBankInfo;

  return (
    <Box
      className={`list-item ${isSelected ? 'selected-item' : ''}`}
      position="relative"
      key={fund.id}
      onClick={() => {
        if (setSelectedFundId) {
          setSelectedFundId(fund.id);
        }
      }}
    >
      <Stack direction="column" spacing={1.5}>
        <div className="list-item-title">{fund.name}</div>

        <CountBadge
          text="Pending approvals"
          noContentText="No pending approvals"
          errorText="Missing banking information"
          count={Array.isArray(pendingPayments) && !isError ? pendingPayments.length : 0}
          isError={isError}
          isSelected={isSelected}
        />
      </Stack>

      {isError && <ErrorPointer />}
    </Box>
  );
};

const ErrorPointer = () => (
  <Box
    width="12px"
    height="12px"
    borderRadius="50%"
    position="absolute"
    top="12px"
    right="12px"
    sx={{
      background: '#FF4747',
    }}
  />
);
