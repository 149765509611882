import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { getUnitsByAssetId } from 'src/services/partnershipService';
import { ApiError } from 'src/types/api';

export const useUnitsByAssetId = (assetId: string | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['unitDetails', assetId],
    queryFn: async () => {
      if (assetId) {
        try {
          const response = await getUnitsByAssetId(assetId);

          if (response?.data?.itemList) {
            return response?.data?.itemList;
          }

          return [];
        } catch (e) {
          console.log('useUnitById error', e);
          showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
          throw new Error((e as AxiosError<ApiError>).response?.data.message);
        }
      }
      throw new Error('No Asset was provided');
    },
  });
};
