import styles from './styles.module.scss';
import IconAlert from '../../../uiLibrary/icons/IconAlert';

const Banner = ({
  variant = 'warning',
  title = '',
  subtitle = '',
  actionComponent = <div>action</div>,
}: BannerProps) => {
  return (
    <div
      className={styles.bannerContainer}
      style={
        variant === 'warning'
          ? { backgroundColor: '#FFF7EB', border: '2px solid #FBAD37' }
          : variant === 'error'
            ? { backgroundColor: '#FFEDED', border: '2px solid #FF4747' }
            : { backgroundColor: '#f6f5f4', border: '2px solid #8f8f8f' }
      }
    >
      <div
        className={styles.iconBox}
        style={
          variant === 'warning'
            ? { backgroundColor: '#FBAD37' }
            : variant === 'error'
              ? { backgroundColor: '#FF4747' }
              : { backgroundColor: '#8f8f8f' }
        }
      >
        <IconAlert />
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.textWrap}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.actionBox}>{actionComponent}</div>
      </div>
    </div>
  );
};

interface BannerProps {
  variant?: 'warning' | 'error' | 'default';
  title?: string;
  subtitle?: string;
  actionComponent?: React.ReactNode;
}
export default Banner;
