import { useState } from 'react';
import { useProtected } from 'src/hooks/useProtected';
import { format } from 'date-fns';
import { ExpandLess } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ButtonGroup, IconButton, ListItemButton } from '@mui/material';
import classNames from 'classnames';
import { useModal } from 'src/hooks/useModal';
import { MenuPopup } from 'src/components/common/MenuPopup/MenuPopup';
import { ManageDocumentModal } from 'src/components/manage-document-modal/manageDocumentModal';
import IconDocument from 'src/uiLibrary/icons/IconDocument';
import IconMultipleFiles from 'src/uiLibrary/icons/IconMultipleFiles';
import { DocumentData } from './document-card-helpers';
import { DocumentCardBody } from './document-card-body/DocumentCardBody';
import styles from 'src/components/common/MenuPopup/styles.module.scss';
import { Dialog } from 'src/components/common';
import { Permission } from 'src/consts/permissions';

enum DocumentCardModal {
  ManageFolder,
}

type DocumentCardProps = {
  document: DocumentData;
  handleFamilyDetailsOnClick?: (documentId: string | number) => void;
  handleDeleteOnClick?: (documentId: number, documentName: string) => void;
};

const DocumentCard = ({ document, handleFamilyDetailsOnClick, handleDeleteOnClick }: DocumentCardProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isOpen, handleOpenModal, handleCloseModal } = useModal<DocumentCardModal>();

  const { id, isExpandable, extension, date, name } = document;

  const cardIcon = extension === 'fam' ? <IconMultipleFiles /> : <IconDocument />;

  const creationDate = format(date, 'dd MMM yyyy');

  const actionButtonIcon = isMenuOpen ? <ExpandLess /> : <ExpandMore />;

  const isEditAvailable = useProtected({
    permissions: [Permission.DocumentsEdit, Permission.CustomFolderManagement, Permission.Documents],
  });
  const isDeleteAvailable = useProtected({
    permissions: [Permission.DocumentsDelete, Permission.Documents],
  });
  const isActionsAvailable = isEditAvailable || isDeleteAvailable;

  return (
    <>
      <div
        className={classNames('document-card', {
          'document-card-not-expandable': !isExpandable,
          'document-card-expanded': isExpandable && isMenuOpen,
        })}
      >
        <div className="document-card-header">
          <div className="card-icon">
            {cardIcon}
            <p>{extension.toUpperCase()}</p>
          </div>
          <div className="card-data">
            <div className="card-title-container">
              <h3>{name}</h3>
            </div>
            <h5>{creationDate}</h5>
          </div>
          {isExpandable && (
            <div className="card-actions">
              <ButtonGroup className="action-button-group" variant="contained">
                {isActionsAvailable && (
                  <MenuPopup title="Document actions">
                    {isEditAvailable && (
                      <ListItemButton
                        className={styles['menu-popup-list-button']}
                        onClick={() => handleOpenModal(DocumentCardModal.ManageFolder)}
                      >
                        <p className={styles['menu-popup-list-button-title']}>Move to folder</p>
                      </ListItemButton>
                    )}

                    {isDeleteAvailable && (
                      <ListItemButton
                        className={styles['menu-popup-list-button']}
                        onClick={() => handleDeleteOnClick && handleDeleteOnClick(+id, name)}
                      >
                        <p className={styles['menu-popup-list-button-red-title']}>Delete</p>
                      </ListItemButton>
                    )}
                  </MenuPopup>
                )}

                <IconButton className="action-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  {actionButtonIcon}
                </IconButton>
              </ButtonGroup>
            </div>
          )}
        </div>
        <DocumentCardBody
          document={document}
          isOpen={isMenuOpen}
          handleFamilyDetailsOnClick={handleFamilyDetailsOnClick}
        />
      </div>
      <Dialog
        open={isOpen(DocumentCardModal.ManageFolder)}
        onClose={handleCloseModal}
        title={'Please select the folder'}
      >
        <ManageDocumentModal document={document} closeModal={handleCloseModal} />
      </Dialog>
    </>
  );
};

export default DocumentCard;
