import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TransactionDetailsWidget from '../../components/manual-payment/transactionDetailsWidget';
import IconArrowLeft from '../../uiLibrary/icons/IconArrowLeft';
import PaymentStatusWidget from '../../components/manual-payment/paymentStatusWidget';
import { useCallback, useMemo, useState } from 'react';
import {
  ManualPaymentStep,
  MANUAL_PAYMENT_STATUS as PAYMENT_STATUS,
  manualPaymentTransactionType,
} from '../../models/manualPaymentModels';
import ChangePaymentStatusButton from '../../components/manual-payment/ChangePaymentStatusButton';
import ConfirmPaymentReceivedModal from '../../uiLibrary/modals/manual-payment/ConfirmPaymentReceived';
import ConfirmPaymentSentModal from '../../uiLibrary/modals/manual-payment/ConfirmPaymentSent';

import styles from './styles.module.scss';
import PaymentBankingSummaryWidget from '../../components/manual-payment/paymentBankingSummaryWidget';
import { addAttachedFile, markPaymentAsReceived, markPaymentAsSent } from '../../services/paymentService';
import { formatDateNoTime } from '../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// const PaymentBankingSummaryWidget = React.lazy(
//   () => import("../../components/manual-payment/paymentBankingSummaryWidget"),
// );

const ManualPaymentStatusComponent = ({ transaction }: ManualPaymentStatusComponentProps) => {
  const userDetails = useSelector((state: RootState) => state.auth.userDetails);
  const { userId } = userDetails || {};
  const [steps, setSteps] = useState<ManualPaymentStep[]>(transaction.steps);
  const [paymentSentModalVisible, setPaymentSentModalVisible] = useState(false);
  const [paymentReceivedModalVisible, setPaymentReceivedModalVisible] = useState(false);

  const isBuyer = transaction.buyerId === userId;
  const isSeller = transaction.sellerId === userId;

  const navigate = useNavigate();

  const currentPaymentStatus = useMemo(() => {
    let latestStep = steps[0];
    steps.forEach(step => {
      if (step.timestamp) {
        latestStep = step;
      }
    });
    return latestStep.status;
  }, [steps]);

  const handleStatusChange = useCallback((nextStatus: PAYMENT_STATUS) => {
    if (nextStatus === PAYMENT_STATUS.SENT) {
      setPaymentSentModalVisible(true);
    }
    if (nextStatus === PAYMENT_STATUS.RECEIVED) {
      setPaymentReceivedModalVisible(true);
    }
  }, []);

  const handlePaymentReceivedConfirmation = useCallback(async () => {
    setPaymentReceivedModalVisible(false);
    const result = await markPaymentAsReceived(transaction.paymentId, transaction.amount);
    if (result && result?.data?.data?.ReceivedDate) {
      setSteps(prevSteps =>
        prevSteps.map(step => {
          if (step.status === PAYMENT_STATUS.RECEIVED) {
            return {
              ...step,
              timestamp: formatDateNoTime(result?.data?.data?.ReceivedDate),
            };
          }
          return step;
        }),
      );
    }
  }, [transaction]);

  const handlePaymentSentConfirmation = useCallback(
    async (files: File[]) => {
      setPaymentSentModalVisible(false);
      const statusResult = await markPaymentAsSent(transaction.paymentId, transaction.amount);
      if (statusResult && statusResult?.data?.data?.SentDate) {
        const fileResult = await addAttachedFile(transaction.paymentId, files[0]);
        setSteps(prevSteps =>
          prevSteps.map(step => {
            if (step.status === PAYMENT_STATUS.SENT) {
              return {
                ...step,
                timestamp: formatDateNoTime(statusResult?.data?.data?.SentDate),
                attachments: fileResult.data?.data?.ReceiptId ? [fileResult.data?.data?.ReceiptId] : [],
              };
            }
            return step;
          }),
        );
      }
    },
    [transaction],
  );

  return (
    <div className="main-body-content">
      <div className={styles.headerRow}>
        <div className={styles.pageTitleContainer}>
          <div className="back-login" onClick={() => navigate(-1)}>
            <div className="back-text">
              <IconButton size="large" component="span">
                <IconArrowLeft stroke="#8F8F8F" />
              </IconButton>
              Back
            </div>
          </div>
          <div className={styles.pageTitle}>Manual Payment</div>
          <div className={styles.pageSubtitle}>{transaction.id}</div>
        </div>
        <ChangePaymentStatusButton
          status={currentPaymentStatus}
          isBuyer={isBuyer}
          isSeller={isSeller}
          category={transaction.category}
          onChangeStatus={handleStatusChange}
        />
      </div>
      <div className={styles.paymentContentWrapper}>
        <TransactionDetailsWidget transactionDetails={transaction} />
        <PaymentBankingSummaryWidget receiverInfo={transaction} />

        {/* <Suspense fallback={{}}>
          <PaymentBankingSummaryWidget />
        </Suspense> */}

        <PaymentStatusWidget transaction={transaction} steps={steps} />
      </div>

      <ConfirmPaymentSentModal
        isVisible={paymentSentModalVisible}
        onCancel={() => setPaymentSentModalVisible(false)}
        onConfirm={handlePaymentSentConfirmation}
      />
      <ConfirmPaymentReceivedModal
        isVisible={paymentReceivedModalVisible}
        onCancel={() => setPaymentReceivedModalVisible(false)}
        onConfirm={handlePaymentReceivedConfirmation}
      />
    </div>
  );
};

interface ManualPaymentStatusComponentProps {
  transaction: manualPaymentTransactionType;
}

export default ManualPaymentStatusComponent;
