import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getBankAccountDetails } from 'src/services/bankingService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { ApiError } from 'src/types/api';
import { filterBankAccountDetailsForEditForm } from './filters/useBankAccountDetails.filters';

export type UseBanksAccountDetailsProps = UseQueryOptions & {
  bankAccountId: number;
  filter?: 'edit';
};

export const useBankAccountDetails = ({ bankAccountId, filter, ...props }: UseBanksAccountDetailsProps) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchBanks = async () => {
    try {
      const response = await getBankAccountDetails(bankAccountId);

      return response.data?.data;
    } catch (e) {
      console.log('Use banks account details error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
      throw new Error((e as AxiosError<ApiError>).response?.data.message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.BankAccountDetails, bankAccountId],
    queryFn: fetchBanks,
    select: data => {
      if (filter == 'edit') {
        return filterBankAccountDetailsForEditForm(data as Record<string, unknown>);
      }

      return data;
    },
    ...props,
  });
};
