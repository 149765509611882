import { ThemeCssVarOverrides } from '@mui/material';

export const textFieldComponentsTheme: ThemeCssVarOverrides = {
  MuiTextField: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        minHeight: '46px',

        '.MuiInputBase-input': {
          background: '#f6f5f4',
          borderRadius: 8,
          border: '1px solid #E1E1E1',
        },

        '.MuiOutlinedInput-root.Mui-error': {
          '.MuiInputBase-input': {
            border: '1px solid #FF5953',
          },
        },

        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
    },
  },
};
