import { useState } from 'react';
import { useFunds } from 'src/hooks/react-query/funds';
import { Stack, Loader } from 'src/components/common';
import { FundsListPreselection } from 'src/components/banking/gp/preselection/fundListPreselection';
import { PreselectionPreview } from 'src/components/banking/gp/preselection/preselectionPreview';
import { GpBankingPreselectionContextProvider } from './GpBankingPreselection.context';

export const GpBankingPreselection = () => {
  const { data: funds, isLoading: isFundsLoading } = useFunds('Banking');
  const [selectedFundId, setSelectedFundId] = useState<number | undefined>();

  if (!funds || isFundsLoading) {
    return <Loader />;
  }

  return (
    <GpBankingPreselectionContextProvider
      value={{
        selectedFundId,
        setSelectedFundId,
      }}
    >
      <Stack direction="row" className="preselection-wrapper">
        <FundsListPreselection funds={funds} />

        <PreselectionPreview />
      </Stack>
    </GpBankingPreselectionContextProvider>
  );
};
