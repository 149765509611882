import { usePagination } from 'src/hooks/usePagination';
import { Table } from 'src/components/common/MuiTable/Table';

export const PendingApprovalsTable = () => {
  const { page, pageSize, setPageSize, setActivePage } = usePagination();

  return (
    <Table
      data={[]}
      columns={[]}
      pagination={{
        page,
        rowsPerPage: pageSize,
        onPageChange: setActivePage,
        onPageSizeChange: setPageSize,
      }}
    />
  );
};
