import { Box, Fade, IconButton, Modal as MUIModal, styled } from '@mui/material';
import IconArrowLeft from '../uiLibrary/icons/IconArrowLeft';

const StyledModal = styled(MUIModal)({
  '& .title': {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '12px',
  },
  '& p': {
    fontWeight: '400',
    fontSize: '14px',
    color: '#8F8F8F',
    lineHeight: '22px',
  },
});

const Modal = ({ children, open, onClose, title, showBack = false }: ModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      hideBackdrop
      BackdropProps={{
        sx: {
          visibility: 'hidden',
        },
      }}
      sx={{
        backdropFilter: 'blur(20px)',
        background: ' rgba(246, 245, 244, 0.50)',
        display: 'grid',
        placeItems: 'center',
        overflow: 'scroll',
        maxHeight: '100%',
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            background: 'white',
            padding: '44px',
            borderRadius: '16px',
            border: '1px solid #E1E1E1',
            maxWidth: '500px',
          }}
        >
          {showBack && (
            <div className="back-login" onClick={onClose}>
              <div className="back-text">
                <IconButton size="large" component="span">
                  <IconArrowLeft stroke="#8F8F8F" />
                </IconButton>
                Back
              </div>
            </div>
          )}

          <h2 className="title">{title}</h2>
          {children}
        </Box>
      </Fade>
    </StyledModal>
  );
};

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  showBack?: boolean;
}

export default Modal;
