import { useMemo } from 'react';
import { ManualPaymentStep, manualPaymentTransactionType } from '../../models/manualPaymentModels';
import AttachmentIndicator from './AttachmentIndicator';

import styles from './styles.module.scss';
import { getAttachedFile } from '../../services/paymentService';

const PaymentStatusWidget = ({ transaction, steps }: PaymentStatusWidgetProps) => {
  const completedSteps = useMemo(() => {
    let completedSteps = 0;
    steps.forEach(step => {
      if (step.timestamp) {
        completedSteps++;
      }
    });
    return completedSteps;
  }, [steps]);

  const downloadFile = async (fileId: string | undefined) => {
    if (fileId) {
      const result = await getAttachedFile(fileId);
      console.log(result);
    }
  };

  return (
    <div className={styles.paymentStatusWidget}>
      <div className={styles.widgetHeaderWrapper}>
        <div className={styles.widgetTitle}>Payment status</div>
      </div>

      <div className={styles.separator} />

      <div className={styles.nodelistWrapper}>
        <div
          className={styles.progressBar}
          style={
            transaction.category === 'distributionPayment'
              ? {
                  background: `linear-gradient(180deg, #61C2A0 25%,${
                    completedSteps > 1 ? '#61C2A0 25%, #61C2A0 75%' : '#F2F2F2 25%, #F2F2F2 75%'
                  })`,
                }
              : {
                  background: `linear-gradient(180deg, #61C2A0 10%,${
                    completedSteps > 1 ? '#61C2A0 10%, #61C2A0 50%' : '#F2F2F2 10%, #F2F2F2 50%'
                  },${completedSteps > 2 ? '#61C2A0 50%, #61C2A0 90%' : '#F2F2F2 50%, #F2F2F2 90%'})`,
                }
          }
        />
        {steps.map(step => {
          return (
            <div className={`${styles.stepContainer} ${step.timestamp ? styles.activeNode : ''}`}>
              <div className={styles.nodeInfoWrapper}>
                <div className={styles.progressBarSection}>
                  <div className={styles.nodeBullet} />
                </div>
                <div className={styles.stepDescription}>
                  <div className={styles.stepTitle}>{step.status}</div>
                  <div className={styles.stepTimestamp}>{step.timestamp}</div>
                </div>
              </div>
              {step.timestamp && !!step.attachments && (
                <AttachmentIndicator
                  count={step.attachments?.length}
                  onClick={() => downloadFile(step.attachments?.[0])}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface PaymentStatusWidgetProps {
  transaction: manualPaymentTransactionType;
  steps: ManualPaymentStep[];
}

export default PaymentStatusWidget;
