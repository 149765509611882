import { useState } from 'react';
import { Grid } from 'src/components/common';
import { PendingPaymentsTable } from './tabs/PendingPaymentsTable';
import { TransactionHistoryTable } from './tabs/TransactionHistoryTable';
import { PendingApprovalsTable } from './tabs/PendingApprovalsTable';

enum Tabs {
  PendingPayments = 'pending-payments',
  TransactionHistory = 'transaction-history',
  PendingAprovals = 'pending-aprovals',
}

export const BankingPaymentsContainer = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.PendingPayments);

  const isActiveTab = (tab: string) => {
    return selectedTab === tab;
  };

  return (
    <>
      <div className="banking-payments-and-transactions-table-header">
        <div
          className={`table-header-tab ${isActiveTab(Tabs.PendingPayments) ? 'selected-tab' : ''}`}
          onClick={() => setSelectedTab(Tabs.PendingPayments)}
        >
          Pending payments
        </div>
        <div
          className={`table-header-tab ${isActiveTab(Tabs.TransactionHistory) ? 'selected-tab' : ''}`}
          onClick={() => setSelectedTab(Tabs.TransactionHistory)}
        >
          Transaction history
        </div>
        <div
          className={`table-header-tab ${isActiveTab(Tabs.PendingAprovals) ? 'selected-tab' : ''}`}
          onClick={() => setSelectedTab(Tabs.PendingAprovals)}
        >
          Pending approvals
        </div>
      </div>
      <div className="banking-table-container wrapper-with-horizontal-scroll">
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          {isActiveTab(Tabs.PendingPayments) && <PendingPaymentsTable />}
          {isActiveTab(Tabs.TransactionHistory) && <TransactionHistoryTable />}
          {isActiveTab(Tabs.PendingAprovals) && <PendingApprovalsTable />}
        </Grid>
      </div>
    </>
  );
};
