import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { updateBankAccount } from 'src/services/bankingService';
import { ApiError } from 'src/types/api';
import { UpdateBankAccountPayload } from 'src/models/bankingModel';
import { QueryKey } from 'src/consts/query';

type UseUpdateBankAccountOptions = {
  bankAccountId: number;
  handleClose?: () => void;
};

export const useUpdateBankAccount = ({ bankAccountId, handleClose }: UseUpdateBankAccountOptions) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (body: UpdateBankAccountPayload) => updateBankAccount(bankAccountId, body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FundBankDetails] });
      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === QueryKey.BankAccountDetails,
      });

      showSuccessSnackbar('Bank account updated');

      if (handleClose) {
        handleClose();
      }
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
