import { ThemeCssVarOverrides } from '@mui/material';

export const switchComponentTheme: ThemeCssVarOverrides = {
  MuiSwitch: {
    defaultProps: {
      size: 'medium',
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 54,
        height: 28,
        padding: 0,
      },
      switchBase: {
        padding: 0,
        margin: 3,
        transition: 'all 0.2s linear',
        boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.1)',

        '&.Mui-checked': {
          transform: 'translateX(26px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#61c2a0',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#61c2a0',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: '#f5f5f5',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      thumb: {
        width: 22,
        height: 22,
      },
      track: {
        borderRadius: 13,
        backgroundColor: '#e1e1e1',
        opacity: 1,
      },
    },
  },
};
