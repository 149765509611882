import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import useSlideAnimation from 'src/hooks/animation/useSlideAnimation';
import { FundsPerformanceInformation } from 'src/models/dashboardModel';
import FundIndicator from 'src/components/FundIndicator';
import { Button } from '@mui/material';
import { onScrollItems } from 'src/utils/scrollItems';

interface FundsPerformanceSlideComponentProps {
  data: FundsPerformanceInformation[][];
}

export const FundsPerformanceSlideComponent = ({ data }: FundsPerformanceSlideComponentProps) => {
  const [selectedFundsPerformanceSlidePage, setSelectedFundsPerformanceSlidePage] = useState(0);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { selectedSlidePage, showFundsInformation } = useSlideAnimation(selectedFundsPerformanceSlidePage, data.length);

  const handleSelectorFundsPerformanceOnClick = (id: number) => {
    setSelectedFundsPerformanceSlidePage(id);
  };

  const handleChangeFund = (alight: 'prev' | 'next') => {
    if (!data) return;

    const fundsCount = data.length;

    const currentIndex =
      (data.findIndex((_, index) => index === selectedSlidePage) + (alight === 'next' ? 1 : -1) + fundsCount) %
      fundsCount;

    handleSelectorFundsPerformanceOnClick(currentIndex);
  };

  return (
    <>
      <div className="prev-area" onClick={() => handleChangeFund('prev')} />
      <div className="funds-performance-slide-header">
        <div className="funds-performance-slide-header-text">
          <h4>Funds Performance</h4>
        </div>
        <div className="funds-performance-slider">
          {data.length > 13 && (
            <Button onClick={() => onScrollItems('left', scrollRef)} variant="text">
              {'<'}
            </Button>
          )}
          <div
            className="funds-performance-slide-header-buttons"
            ref={scrollRef}
            style={{ overflowX: 'auto', whiteSpace: 'nowrap' }} // Ensure horizontal scrolling
          >
            {data.length &&
              data.map((_, index) => (
                <button
                  key={index}
                  value={index}
                  className={classNames('selector', { active: selectedSlidePage === index })}
                  onClick={() => handleSelectorFundsPerformanceOnClick(index)}
                />
              ))}
          </div>
          {data.length > 13 && (
            <Button onClick={() => onScrollItems('right', scrollRef)} variant="text">
              {'>'}
            </Button>
          )}
        </div>
      </div>
      <div className={`funds-performance-slide-body ${showFundsInformation}`}>
        {data[selectedSlidePage]?.map(indicator => <FundIndicator key={indicator.id} fund={indicator} />)}
      </div>
      <div
        className={classNames('next-area', { primary: data[selectedSlidePage]?.length !== 2 })}
        onClick={() => handleChangeFund('next')}
      />
    </>
  );
};
