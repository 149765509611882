import { validateError } from 'src/utils/validateError';
import {
  DocSortField,
  DocSortOrder,
  DocFilterType,
  LpSortOrder,
  LpSortField,
  TenantSortField,
  TenantSortOrder,
} from '../models/partnershipModel';
import { AddSellReqPayload } from '../uiLibrary/modals/addPartnershipSellRequest/addPartnershipSellRequestContainer';
import { CapCallPayload } from '../uiLibrary/modals/createCapCall/createCapCallContainer';
import ServiceClient from './serviceClientConfig';
import { AxiosResponse } from 'axios';
import { AssetById } from 'src/types/Assets';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getGpPartnershipListing = (
  page: number,
  userId: number,
  minPrice: number | undefined,
  maxPrice: number | undefined,
  searchString: string,
) => {
  let url = `gp-partnership/frontend/list?page=${page}&userId=${userId}`;
  if (minPrice) url += '&MinPrice=' + minPrice;
  if (maxPrice) url += '&MaxPrice=' + maxPrice;
  if (searchString) url += '&Search=' + searchString;
  return serviceClient.get(url);
};
export const partnershipDetailsById = async (partnershipId: string) => {
  try {
    const response = await serviceClient.get(`gp-partnership/frontend/partnership-details/${partnershipId}`);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('partnershipDetailsById error: ', error);
    throw await validateError(error);
  }
};
export const getInvestChart = (partnershipId: number, isUser?: boolean) => {
  if (isUser) {
    return serviceClient.get(`gp-partnership/partnership/dept?userId=${partnershipId}`);
  } else {
    return serviceClient.get(`gp-partnership/partnership/dept?partnershipId=${partnershipId}`);
  }
};
export const getLpInvestChart = (partnershipId: number) => {
  return serviceClient.get(`lp-user-partnership/partnership/dept/?partnershipId=${partnershipId}`);
};
export const getProfitLossChart = (partnershipId: number, isUser?: boolean) => {
  if (isUser) {
    return serviceClient.get(`gp-partnership/partnership/profit-loss?userId=${partnershipId}`);
  } else {
    return serviceClient.get(`gp-partnership/partnership/profit-loss?partnershipId=${partnershipId}`);
  }
};
export const getLpProfitLossChart = (partnershipId: number) => {
  return serviceClient.get(`lp-user-partnership/partnership/profit-loss/?partnershipId=${partnershipId}`);
};
export const getGpDividendChart = (partnershipId: number, isUser?: boolean) => {
  if (isUser) {
    return serviceClient.get(`gp-partnership/partnership/partnership-dividend?userId=${partnershipId}`);
  } else {
    return serviceClient.get(`gp-partnership/partnership/partnership-dividend?partnershipId=${partnershipId}`);
  }
};
export const getLpDividendChart = (partnershipId: number) => {
  return serviceClient.get(`lp-user-partnership/partnership/partnership-dividend?partnershipId=${partnershipId}`);
};

export const getCashYieldChart = (partnershipId: number, isUser?: boolean) => {
  if (isUser) {
    return serviceClient.get(`gp-partnership/partnership/CashYield?userId=${partnershipId}`);
  } else {
    return serviceClient.get(`gp-partnership/partnership/CashYield?partnershipId=${partnershipId}`);
  }
};
export const getLpCashYieldChart = (partnershipId: number) => {
  return serviceClient.get(`lp-user-partnership/partnership/CashYield?partnershipId=${partnershipId}`);
};
export const getOverallStats = (partnershipId: number) => {
  return serviceClient.get(`gp-partnership/partnership/total-invest/${partnershipId}`);
};
export const getLpOverallStats = (partnershipId: number) => {
  return serviceClient.get(`lp-user-partnership/partnership-stats/${partnershipId}`);
};
export const createCapCall = (payload: CapCallPayload) => {
  return serviceClient.post(`gp-partnership/frontend/Cap-Call`, payload);
};

export const getAssetDetailsById = async (assetId: string) => {
  try {
    const response = await serviceClient.get(`/partnerships/asset/${assetId}`);

    return response.data as AxiosResponse<AssetById>;
  } catch (error) {
    console.error('getAssetDetailsById error: ', error);
    throw await validateError(error);
  }
};

export const getUnitsByAssetId = async (assetId?: string) => {
  try {
    const params = {
      ...(assetId && { assetId: assetId }),
    };

    const response = await serviceClient.get(`units`, { params });

    return response.data as AxiosResponse;
  } catch (error) {
    console.error('getUnitDetailsById error: ', error);
    throw await validateError(error);
  }
};

export const getLPDocumentList = (
  partnershipId: number,
  page: number,
  limit: number,
  orderField: DocSortField,
  orderSort: DocSortOrder,
  reportFilterType: DocFilterType,
  freqFilterType: DocFilterType,
  searchString?: string,
) => {
  let url = `lp-user-partnership/partnership/documents/${partnershipId}?Page=${page}&Limit=${limit}&SortFields=${orderField}&Sort=${orderSort.toUpperCase()}`;
  if (reportFilterType) {
    url += `&ReportFilter=${reportFilterType}`;
  }
  if (freqFilterType) {
    url += `&ReportFrequency=${freqFilterType}`;
  }
  if (searchString) {
    url += `&Name=${searchString}`;
  }
  return serviceClient.get(url);
};
export const getDocumentList = (
  partnershipId: number,
  page: number,
  limit: number,
  orderField: DocSortField,
  orderSort: DocSortOrder,
  reportFilterType: DocFilterType,
  freqFilterType: DocFilterType,
  searchString?: string,
) => {
  let url = `gp-partnership/frontend/partnership/documents/${partnershipId}?Page=${page}&Limit=${limit}&SortFields=${orderField}&Sort=${orderSort.toUpperCase()}`;
  if (reportFilterType) {
    url += `&ReportFilter=${reportFilterType}`;
  }
  if (freqFilterType) {
    url += `&ReportFrequency=${freqFilterType}`;
  }
  if (searchString) {
    url += `&Name=${searchString}`;
  }
  return serviceClient.get(url);
};
export const getAssetDocumentList = async (
  assetId: number,
  partnershipId: string,
  page: number,
  limit: number,
  orderField: DocSortField,
  orderSort: DocSortOrder,
  reportFilterType: DocFilterType,
  freqFilterType: DocFilterType,
  searchString?: string,
) => {
  let url = `gp-partnership/partnership/assets/documents?AssetsId=${assetId}&PartnershipId=${partnershipId}&Page=${page}&Limit=${limit}&SortFields=${orderField}&Sort=${orderSort.toUpperCase()}`;
  if (reportFilterType) {
    url += `&ReportFilter=${reportFilterType}`;
  }
  if (freqFilterType) {
    url += `&ReportFrequency=${freqFilterType}`;
  }
  if (searchString) {
    url += `&Name=${searchString}`;
  }
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getAssetDocumentList error: ', error);
    throw await validateError(error);
  }
};

export const getLimitedPartnerList = (
  partnershipId: number,
  lpPage: number,
  lpSearchString: string,
  lpSortOrder: LpSortOrder,
  lpSortField: LpSortField,
  lpLimit: number,
) => {
  let url = `gp-partnership/frontend/lp-users/${partnershipId}?page=${lpPage}&limit=${lpLimit}&order=${lpSortField}&orderBy=${lpSortOrder.toUpperCase()}`;
  if (lpSearchString) {
    url += `&name=${lpSearchString}`;
  }
  return serviceClient.get(url);
};
export const getTenantList = async (
  assetsId: number,
  page: number,
  limit: number,
  orderField: TenantSortField,
  orderSort: TenantSortOrder,
  searchString?: string,
) => {
  let url = `gp-partnership/partnership/tenants/${assetsId}?Page=${page}&Limit=${limit}&SortFields=${orderField}&Order=${orderSort.toUpperCase()}`;

  if (searchString) {
    url += `&Name=${searchString}`;
  }

  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getTenantList error: ', error);
    throw await validateError(error);
  }
};

export const getActivityList = (page: number, limit: number, partnershipId?: string) => {
  const url = `gp-partnership/partnership/activities/${partnershipId}?Page=${page}&Limit=${limit}`;
  return serviceClient.get(url);
};
export const getAssetStatistics = (assetId: string) => {
  const url = `gp-partnership/partnership/asset/stats/${assetId}`;
  return serviceClient.get(url);
};
export const getSellReqDetails = (partnershipId: string) => {
  return serviceClient.get('lp-partnership-details/' + partnershipId);
};
export const addSellRequest = (payload: AddSellReqPayload, partnershipId: number) => {
  return serviceClient.post(`sell-request/${partnershipId}`, payload);
};
export const isSellReqEligible = (partnershipId: string) => {
  return serviceClient.get(`check-sell-call/${partnershipId}`);
};

export const countryStateList = (countryId?: string) => {
  let url = 'users/user/country-state';
  if (countryId) {
    url = url + '?CountryId=' + countryId;
  }
  return serviceClient.get(url);
};

export const getAssetKpis = async (assetId: string) => {
  const url = `assets-kpis/${assetId}`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getAssetKpis error: ', error);
    throw await validateError(error);
  }
};

// TODO: yearly?
export const getKpiList = (fundName: string, assetName: string) => {
  const url = `kpi/list?fund=${fundName}&asset=${assetName}&yearly=true`;
  return serviceClient.get(url);
};
// TODO: yearly?
export const getKpiByName = (fundName: string, assetName: string, kpiName: string) => {
  const url = `kpi/list?fund=${fundName}&asset=${assetName}&kpi=${kpiName}&yearly=true`;
  return serviceClient.get(url);
};

// TODO: yearly?
export const getInvestmentKpiList = async (fundName: string, investmentName: string) => {
  const url = `kpi/list?fund=${fundName}&investment=${investmentName}&yearly=true`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getInvestmentKpiList error: ', error);
    throw await validateError(error);
  }
};

export const getInvestmentKpiByName = async (fundName: string, investmentName: string, kpiName: string) => {
  const url = `kpi/list?fund=${fundName}&investment=${investmentName}&kpi=${encodeURIComponent(kpiName)}&yearly=true`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getInvestmentKpiByName error: ', error);
    throw await validateError(error);
  }
};
