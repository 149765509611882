import { useForm, Controller } from 'react-hook-form';
import { Grid, FormControl, FormLabel, TextField, Select, MenuItem, Separator, Button } from 'src/components/common';
import { CreateBankAccountPayload, UpdateBankAccountPayload } from 'src/models/bankingModel';
import { yupResolver } from '@hookform/resolvers/yup';
import validationSchema from './BankAccountCreation.validation';

type BankAccountCreationFormProps = {
  defaultValues: CreateBankAccountPayload | UpdateBankAccountPayload;
  onSubmit: (data: CreateBankAccountPayload | UpdateBankAccountPayload) => void;
  isSubmitting?: boolean;
  existingBankingAccounts: Array<string>;
};

const accountTypeOptions = [
  { value: 'checking', label: 'Checking' },
  { value: 'savings', label: 'Savings' },
];

export const BankAccountCreationForm = ({
  defaultValues,
  onSubmit,
  isSubmitting,
  existingBankingAccounts,
}: BankAccountCreationFormProps) => {
  const { control, handleSubmit } = useForm<CreateBankAccountPayload | UpdateBankAccountPayload>({
    mode: 'onChange',
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid container component="form" spacing="16px" sx={{ ml: '-16px !important' }} onSubmit={handleSubmit(onSubmit!)}>
      <Grid item sm={12}>
        <Controller
          name="Alias"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Account alias</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          name="AccountHolderName"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Account holder name</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="RoutingNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>ACH Routing Number</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="AccountNumber"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Account number</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="BeneficiaryName"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Beneficiary name</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="BeneficiaryBankName"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Beneficiary bank name</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          name="BeneficiaryAddress"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Beneficiary address</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          name="BeneficiaryBankAddress"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Beneficiary bank address</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          name="BankAccountType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Account Type</FormLabel>
              <Select value={value} onChange={onChange}>
                {accountTypeOptions.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={existingBankingAccounts.includes(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="BeneficiaryBankIdSwift"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Beneficiary bank id (Swift)</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <Controller
          name="BranchCode"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl fullWidth variant="outlined">
              <FormLabel required>Branch code</FormLabel>
              <TextField
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
                fullWidth
              />
            </FormControl>
          )}
        />
      </Grid>

      <Grid item sm={12} marginTop={2}>
        <Separator />
      </Grid>

      <Grid item sm={12} marginTop={2}>
        <Button type="submit" order="primary" size="large" disabled={isSubmitting} fullWidth>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  );
};
