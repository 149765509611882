export const typography = {
  s12_lh18_r: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },

  s12_lh19_r: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '19.2px',
  },

  s14_lh21_r: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },

  s14_lh22_r: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22.4px',
  },

  s14_lh22_b: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22.4px',
  },

  s16_lh24_b: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },

  s20_lh32_b: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },
};
