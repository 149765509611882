import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from 'src/assets/images/icons/arrow-right.svg';

type BankingActionWidgetTransactionHistoryProps = {
  fund_Name: string;
};

export const BankingActionWidgetTransactionHistory = ({ fund_Name }: BankingActionWidgetTransactionHistoryProps) => {
  const navigate = useNavigate();
  return (
    <div className="widget-box" style={{ flexBasis: '26%' }}>
      <div className="widget-main-content">
        {/* <div className="widget-label">New Activity</div> */}
        <div className="widget-title">Transaction history</div>
        <div className="widget-subtitle">
          Access the money movements record of the fund. Received capital calls, past capital distributions and other
          operations.
        </div>
        {/* <div className="widget-data-preview">
                    <div className="data-preview-label"></div>
                    <div className="data-row">
                        <div className="data-row-item">
                            <div className="row-item-label"></div>
                            <div className="row-item-value"></div>
                        </div>
                        <div className="data-row-item">
                            <div className="row-item-label"></div>
                            <div className="row-item-value"></div>
                        </div>
                    </div>
                </div> */}
      </div>
      <div className="footer-wrapper">
        <div className="separator" />
        <div
          className="cta-wrapper"
          onClick={() =>
            navigate('transactions-history', {
              state: { fund_Name: fund_Name },
            })
          }
        >
          <div className="cta-text">View transaction history</div>
          <div className="arrow-button-box">
            <img src={ArrowRightIcon} width="12px" height="12px" />
          </div>
        </div>
      </div>
    </div>
  );
};
