import { useCallback, useMemo } from 'react';
import Button from '../common/Button';
import {
  MANUAL_PAYMENT_STATUS as PAYMENT_STATUS,
  manualPaymentTransactionCategoryType,
} from '../../models/manualPaymentModels';

const ChangePaymentStatusButton = ({
  status,
  isBuyer = true,
  isSeller = true,
  category,
  onChangeStatus,
}: ChangePaymentStatusButtonProps) => {
  const buttonText = useMemo(() => {
    switch (status) {
      case PAYMENT_STATUS.PENDING:
        return isBuyer ? 'Mark payment as sent' : 'Pending payment';
      case PAYMENT_STATUS.SENT:
        return category === 'distributionPayment'
          ? 'Transaction completed'
          : isBuyer
            ? 'Payment sent'
            : 'Confirm payment reception';
      case PAYMENT_STATUS.RECEIVED:
        return 'Transaction completed';
    }
  }, [status, isBuyer]);

  const isButtonEnabled = useMemo(() => {
    switch (status) {
      case PAYMENT_STATUS.PENDING:
        return isBuyer;
      case PAYMENT_STATUS.SENT:
        return category === 'distributionPayment' ? false : isSeller;
      case PAYMENT_STATUS.RECEIVED:
        return false;
    }
  }, [status, isBuyer]);

  const handleButtonClick = useCallback(() => {
    switch (status) {
      case PAYMENT_STATUS.PENDING:
        onChangeStatus(PAYMENT_STATUS.SENT);
        return;
      case PAYMENT_STATUS.SENT:
        onChangeStatus(PAYMENT_STATUS.RECEIVED);
        return;
    }
  }, [onChangeStatus, status]);

  return (
    <Button
      customStyle={{ height: 'fit-content' }}
      size="small"
      order="secondary"
      variant="primary"
      disabled={!isButtonEnabled}
      onClick={handleButtonClick}
    >
      {buttonText}
    </Button>
  );
};

interface ChangePaymentStatusButtonProps {
  status: PAYMENT_STATUS;
  isBuyer: boolean;
  isSeller: boolean;
  category: manualPaymentTransactionCategoryType;
  onChangeStatus: (nextStatus: PAYMENT_STATUS) => void;
}

export default ChangePaymentStatusButton;
