const IconArrowRight = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="white" fillOpacity="0.16" />
      <g clipPath="url(#clip0_8790_29554)">
        <path
          transform="translate(4, 4)"
          d="M7.56835 3.76414C7.59923 3.79518 7.62369 3.83199 7.64035 3.87248C7.65729 3.91279 7.66602 3.95608 7.66602 3.99981C7.66602 4.04354 7.65729 4.08683 7.64035 4.12714C7.62369 4.16763 7.59923 4.20444 7.56835 4.23548L4.90168 6.90214C4.87093 6.93398 4.83415 6.95937 4.79348 6.97684C4.75281 6.99431 4.70907 7.00351 4.66481 7.00389C4.62055 7.00428 4.57666 6.99584 4.5357 6.97908C4.49473 6.96232 4.45751 6.93757 4.42622 6.90627C4.39492 6.87498 4.37017 6.83776 4.35341 6.79679C4.33665 6.75583 4.32821 6.71194 4.3286 6.66768C4.32898 6.62342 4.33818 6.57968 4.35565 6.53901C4.37312 6.49834 4.39851 6.46156 4.43035 6.43081L6.52801 4.33314L0.666013 4.33314C0.577607 4.33314 0.492823 4.29802 0.43031 4.23551C0.367799 4.173 0.33268 4.08822 0.33268 3.99981C0.33268 3.9114 0.367799 3.82662 0.430311 3.76411C0.492823 3.7016 0.577607 3.66648 0.666013 3.66648L6.52801 3.66648L4.43035 1.56881C4.36963 1.50594 4.33603 1.42174 4.33679 1.33434C4.33755 1.24694 4.37261 1.16334 4.43441 1.10154C4.49621 1.03973 4.57981 1.00468 4.66721 1.00392C4.75461 1.00316 4.83881 1.03676 4.90168 1.09748L7.56835 3.76414Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8790_29554">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconArrowRight;
