import { Stack, IconButton, Typography } from '@mui/material';
import { ReactComponent as GoBackArrowLeftIcon } from 'src/assets/images/icons/go-back-arrow-left.svg';

type ArrowGoBackButtonProps = {
  text?: string;
  onClick: () => void;
};

export const ArrowGoBackButton = ({ text = 'Back', onClick }: ArrowGoBackButtonProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <IconButton size="large" component="span" onClick={onClick}>
        <GoBackArrowLeftIcon stroke="#8F8F8F" />
      </IconButton>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22.4px',
          color: '#8F8F8F',
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};
