import { renderCurrency } from '../utils';
import ArrowRightIcon from '../../assets/images/icons/arrow-right.svg';
import { useNavigate, useParams } from 'react-router-dom';

const FboHistoryDisplayWidget = ({ fund_Name }: FboHistoryDisplayWidgetProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const mockres = [
    {
      type: 'Funding',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Distribution',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Distribution',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Funding',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Funding',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Distribution',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Distribution',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
    {
      type: 'Funding',
      prevBalance: 1000000,
      amount: 240000,
      fund: 'The Bell enclave',
      date: '28 Oct 2023',
    },
  ];
  return (
    <div className="fbo-history-container">
      <div className="fbo-widget-header">
        <div className="title">ETB transaction history</div>
        <div
          className="link-action"
          onClick={() => {
            navigate(`/gpbanking/${params.fundId}/etb-history`, {
              state: { fund_Name: fund_Name },
            });
          }}
        >
          View details
        </div>
      </div>
      <div className="content-scroll">
        {mockres.map(p => {
          return (
            <div className={`fbo-entry-item ${p.type}-style`}>
              <div className="entry-item-info">
                <div className="type-label">{p.type}</div>
                <div className="amount-content">
                  {renderCurrency(p.prevBalance)}
                  <span>
                    {p.type === 'Funding' ? '+ ' : '- '}
                    {renderCurrency(p.amount)}
                  </span>
                </div>
                <div className="footer-info">
                  <div className="fund-name">{p.fund}</div>·<div className="date">{p.date}</div>
                </div>
              </div>
              <div className="action-button">
                <img src={ArrowRightIcon} width="16px" height="16px" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
interface FboHistoryDisplayWidgetProps {
  fund_Name?: string;
}
export default FboHistoryDisplayWidget;
