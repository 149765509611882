import { useState, useRef } from 'react';
import { Stack, Skeleton } from 'src/components/common';
import AddCircleIcon from 'src/assets/images/icons/add-circle.svg';
import Button from 'src/components/common/Button';
import { BankAccountCard } from 'src/components/banking/common/manage-banking-info';
import { BankingPaymentsContainer } from 'src/components/banking/lp';
import { AddBankAccountModal, EditBankAccountModal } from 'src/views/gp-banking/components/bank-account';

enum Modals {
  AddBankAccount = 'add_bank_account',
  EditBankAccount = 'edit_bank_account',
}

//TODO: Add real data
const bankCardsMock: { [key: string]: string | number }[] = [
  {
    id: 1,
    AliasName: 'First mock',
    AccountNumber: '000123456789',
    RoutingNumber: '110000000',
    BBI: '213DSA12',
    BankName: 'Big bank',
    AccountType: 'checking',
  },
  {
    id: 2,
    AliasName: 'Second mock',
    AccountNumber: '98776654542',
    RoutingNumber: '123456789',
    BBI: '1234',
    BankName: 'Small bank',
    AccountType: 'checking',
  },
];

export const LpBankingInfoManagement = () => {
  const [activeModal, setActiveModal] = useState<Modals | null>();

  const bankAccountId = useRef<null | number>(null);

  const closeModal = () => {
    setActiveModal(null);
    bankAccountId.current = null;
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="column"
          padding={3}
          borderRadius={2}
          sx={{
            background: '#f6f5f4',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button
              order="secondary"
              size="small"
              customStyle={{ height: 'fit-content', paddingLeft: 16, gap: 8 }}
              onClick={() => {
                setActiveModal(Modals.AddBankAccount);
              }}
            >
              <img src={AddCircleIcon} width="16px" height="16px" />
              Add bank account
            </Button>
          </Stack>

          <Stack direction="row" spacing={3} maxHeight="400px">
            {/* //TODO: Add bank accounts */}
            {Array.isArray(bankCardsMock) &&
              bankCardsMock.map(account => {
                return (
                  <Skeleton
                    height={372}
                    width={240}
                    variant="rectangular"
                    sx={{ borderRadius: '16px' }}
                    key={account.id}
                  >
                    <BankAccountCard
                      account={account}
                      onEditClick={() => {
                        bankAccountId.current = account.id as number;
                        setActiveModal(Modals.EditBankAccount);
                      }}
                    />
                  </Skeleton>
                );
              })}
          </Stack>
        </Stack>

        <BankingPaymentsContainer />
      </Stack>

      <AddBankAccountModal open={activeModal === Modals.AddBankAccount} handleClose={closeModal} />
      <EditBankAccountModal
        bankAccountId={bankAccountId.current!}
        open={activeModal === Modals.EditBankAccount}
        handleClose={closeModal}
      />
    </>
  );
};
