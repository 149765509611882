import { renderCurrency } from 'src/views/utils';

interface PendingApprovalCardProps {
  issued: string;
  type: 'cap-call-funding' | 'cap-call-buy-in';
  fundName: string;
  lpName: string;
  paymentDeadline: string;
  amount: number;
}

export const PendingApprovalCard = ({
  issued,
  type,
  fundName,
  lpName,
  paymentDeadline,
  amount,
}: PendingApprovalCardProps) => {
  return (
    <div className="card-body">
      <div className="card-content-row">
        <div className="row-item">
          <div className="item-label">Issued</div>
          <div className="item-content">{issued}</div>
        </div>
        <div className={`chip chip-${type}`}>
          {type === 'cap-call-funding'
            ? 'Capital call funding'
            : type === 'cap-call-buy-in'
              ? 'Capital call buy in'
              : type}
        </div>
      </div>
      <div className="card-content-row">
        <div className="row-item">
          <div className="item-label">Fund name</div>
          <div className="item-content">{fundName}</div>
        </div>
        <div className="row-item">
          <div className="item-label">LP name</div>
          <div className="item-content">{lpName}</div>
        </div>
      </div>
      <div className="card-content-row">
        <div className="row-item">
          <div className="item-label">Payment deadline</div>
          <div className="item-content">{paymentDeadline}</div>
        </div>
        <div className="row-item">
          <div className="item-label">Amount</div>
          <div className="item-content">{renderCurrency(amount)}</div>
        </div>
      </div>
    </div>
  );
};
