import { Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './styles.module.scss';
import WarnIcon from 'src/assets/images/icons/warn-icon.svg';

type CountBadgeProps = {
  text: string;
  noContentText: string;
  count?: number;
  isError?: boolean;
  errorText?: string;
  onClick?: () => void;

  isSelected?: boolean;
};

export const CountBadge = ({
  text,
  noContentText,
  count,
  isError,
  errorText,
  onClick,
  isSelected,
}: CountBadgeProps) => {
  let badgeText = text;

  if (!count) {
    badgeText = noContentText;
  }

  if (isError && errorText) {
    badgeText = errorText;
  }

  const isEmpty = !count || isError;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      className={classNames(styles['count-badge'], {
        [styles.error]: isError,
        [styles.clickable]: !!onClick,
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {!isEmpty && <img src={WarnIcon} width="20px" height="20px" />}

      <Typography variant="s14_lh22_r" className={styles['badge-text']}>
        {badgeText}
      </Typography>

      {!isEmpty && (
        <Typography variant="s14_lh22_b" className={styles['badge-content']}>
          {count}
        </Typography>
      )}
    </Stack>
  );
};
