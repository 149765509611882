import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentsUnitDetailComponent } from './documentsUnitDetailComponent';
import { useFundById } from 'src/hooks/react-query/funds';
import { useAssetDetailsById } from 'src/hooks/react-query/partnership';
import { Loader } from 'src/components/common/Loader';
import ConfirmDialog from 'src/uiLibrary/modals/confirm-modal/confirmDialogContainer';
import { CreateNewFolderModal } from './components/CreateNewFolderModal';
import { UpdateFolderModal } from './components/UpdateFolderModal';
import { useDocumentFolders, useUnitDocuments } from 'src/hooks/react-query/documents';

type ConfirmDialogState = {
  id?: number;
  name?: string;
  open: boolean;
};

type UpdateFolderModalState = {
  open: boolean;
  folderId?: number;
  oldFolderName?: string;
};

export const DocumentsUnitDetailContainer = () => {
  const params = useParams();
  const fundId = Number(params.fundId);
  const assetId = Number(params.assetId);
  const unitId = Number(params.unitId);

  const { data: fundData, isLoading: isFundLoading } = useFundById(fundId);
  const { data: assetDetails, isLoading: isAssetDeailsLoading } = useAssetDetailsById(assetId);

  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogState>({
    open: false,
  });

  const [createFolderModalData, setCreateFolderModalData] = useState({
    open: false,
  });

  const [updateFolderModalData, setUpdateFolderModalData] = useState<UpdateFolderModalState>({
    open: false,
  });

  const { data: unitDocuments } = useUnitDocuments(unitId);

  const { folderList } = useDocumentFolders(unitId, 'unit', !!unitId);

  const handleDeleteOnClick = async (documentId: number, documentName: string) => {
    setConfirmDialogData({ id: documentId, name: documentName, open: true });
  };

  const closeConfirmDialog = () => {
    setConfirmDialogData({
      open: false,
    });
  };

  const openCreateFolderModal = () => {
    setCreateFolderModalData({ open: true });
  };

  const openUpdateFolderModal = (folderId: number, oldFolderName: string) => {
    setUpdateFolderModalData({ open: true, folderId, oldFolderName });
  };

  const deleteDocument = (documentId?: number) => {
    if (!documentId) return;

    //TODO: Add delete
    closeConfirmDialog();
  };

  if (!assetDetails || !fundData || isAssetDeailsLoading || isFundLoading) return <Loader />;

  return (
    <>
      <ConfirmDialog
        isVisible={confirmDialogData.open}
        title={`Are you sure you want to delete the ${confirmDialogData.name ? confirmDialogData.name : 'document'} ?`}
        dialogContentText="You will not be able to restore the file."
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        confirmModal={() => {
          deleteDocument(confirmDialogData.id);
        }}
        closeModal={closeConfirmDialog}
      />

      <CreateNewFolderModal
        open={createFolderModalData.open}
        onClose={() => {
          setCreateFolderModalData({ open: false });
        }}
        unitId={unitId}
      />
      <UpdateFolderModal
        open={updateFolderModalData.open}
        onClose={() => {
          setUpdateFolderModalData({ open: false });
        }}
        assetId={assetId}
        folderId={updateFolderModalData.folderId}
        oldFolderName={updateFolderModalData.oldFolderName}
      />
      <DocumentsUnitDetailComponent
        fund={{
          id: fundData.fund.id,
          name: fundData.fund.name,
        }}
        asset={assetDetails}
        documents={unitDocuments ?? []}
        folders={folderList ?? []}
        handleDeleteOnClick={handleDeleteOnClick}
        openCreateFolderModal={openCreateFolderModal}
        openUpdateFolderModal={openUpdateFolderModal}
      />
    </>
  );
};
