export interface Column {
  id?: string;
  label: string;
  sortable?: boolean;
  actions?: Actions[];
  fixedWidth?: string;
}
export interface Actions {
  label: string;
  icon: string;
  action: (index: number) => void;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortColumn {
  id?: string;
  direction?: SortDirection;
}

export interface Row {
  id: string | number;
  //TODO: remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
