import { Loader } from 'src/components/common/Loader';
import { useCapitalStructureWidget } from 'src/hooks/react-query/funds';
import { calcSumByKey, calcPercentage } from 'src/utils/calcMethods';
import { Box, Stack } from 'src/components/common';
import { useMemo } from 'react';

interface CapitalStructureProps {
  fundId: number;
}

const graphColors = ['#297B80', '#1F666A', '#16575B'];

export const CapitalStructure = ({ fundId }: CapitalStructureProps) => {
  const { data: capitalStructureData, isLoading } = useCapitalStructureWidget(fundId);
  const capitalStructure = useMemo(
    () => (capitalStructureData?.length ? capitalStructureData : []),
    [capitalStructureData],
  );

  if (isLoading) return <Loader />;

  const total = calcSumByKey(capitalStructure, 'value');

  return (
    <Box className="capital-structure-container" display="flex" flexDirection="column" height="100%" minHeight="651px">
      <h3 className="title">Capital structure</h3>

      {capitalStructure.map((graph, index) => {
        const percentage = `${calcPercentage(graph.value, total).toFixed(2)}%`;
        const value = graph.value.toFixed(2);

        return (
          <Stack key={graph.id} direction="row" gap={3} minHeight="12%" height={percentage}>
            <Box
              width="34px"
              sx={{
                backgroundColor: graphColors[index],
              }}
            />

            <Stack direction="column" justifyContent="center">
              <p className="label">{graph.label}</p>

              <p className="value">$ {value}</p>

              <p className="percentage">{percentage}</p>
            </Stack>
          </Stack>
        );
      })}
    </Box>
  );
};
