import { capitalizeFirstLetter } from 'src/views/utils';
import IconArrowSent from 'src/uiLibrary/icons/IconArrowSent';
import IconArrowReceived from 'src/uiLibrary/icons/IconArrowReceived';
import IconAlert from 'src/uiLibrary/icons/IconAlert';
import { PaymentCategory } from 'src/types/payments';

export const renderStatusBadge = (status?: string) => (
  <div className={`status-badge-${capitalizeFirstLetter(status)}`}>
    <p>{capitalizeFirstLetter(status)}</p>
  </div>
);

export const renderCategoryBadge = (category: PaymentCategory) => {
  const categoryName = category
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/gp|lp/g, matched => matched.toUpperCase());

  return (
    <div
      className={`category-badge-${
        category === 'distributionPayment' ? 'ID' : category === 'capitalCallFunding' ? 'CCF' : 'LP'
      }`}
    >
      <p>{capitalizeFirstLetter(categoryName)}</p>
    </div>
  );
};

export const renderTypeBadge = (type: string) => (
  <div className="type-badge">
    <p>{capitalizeFirstLetter(type)}</p>
    <div className={`type-badge-${capitalizeFirstLetter(type)}-arrow`}>
      {capitalizeFirstLetter(type) === 'Sent' ? <IconArrowSent /> : <IconArrowReceived />}
    </div>
  </div>
);

export const renderYouBadge = () => (
  <div className="type-badge">
    <div className="type-badge-alert">
      <IconAlert fill="red" />
    </div>
    <p>You</p>
  </div>
);
