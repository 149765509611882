import { Box } from '@mui/material';
import Button from '../../../components/common/Button';
import Modal from '../../../components/Modal';

const ConfirmPaymentReceived = ({ isVisible, onCancel, onConfirm }: ConfirmPaymentReceivedProps) => {
  return (
    <Modal open={isVisible} onClose={onCancel} title="Confirm payment reception">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
        }}
      >
        <p>
          By approving this, you will confirm the payment has been correctly received and the transaction will be
          completed.
        </p>
        <p>Note: this confirmation can not be rolled back</p>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            marginTop: '32px',
          }}
        >
          <Button customStyle={{ flex: 1 }} size="small" order="secondary" onClick={onCancel} variant="secondary">
            Cancel
          </Button>

          <Button customStyle={{ flex: 1 }} size="small" order="primary" onClick={onConfirm} variant="primary">
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

interface ConfirmPaymentReceivedProps {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default ConfirmPaymentReceived;
