import Button from '@mui/material/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UploadDocumentContainer } from 'src/uiLibrary/modals/uploadDocument/uploadDocumentContainer';
import { useFundById } from 'src/hooks/react-query/funds';
import { useUploadAssetDocument } from 'src/hooks/react-query/documents';
import { Modal } from 'src/components/common/Modal/Modal';
import { useAssetById } from 'src/hooks/react-query/asset';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'src/consts/query';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';

export const UploadDocument = () => {
  const params = useParams();
  const queryClient = useQueryClient();

  const { data: fundInfo } = useFundById(params.fundId);
  const { data: assetDetails } = useAssetById(params.assetId);

  const [openUploadFileModal, setOpenUploadFileModal] = useState<boolean>(false);

  const handleOpenUploadFileModal = () => {
    setOpenUploadFileModal(true);
  };

  const handleCloseUploadFileModal = () => {
    setOpenUploadFileModal(false);
  };

  const refetchDocuments = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.Documents, 'asset', Number(params.assetId)] });
  };

  const { mutate: uploadFileMutation, isLoading } = useUploadAssetDocument(
    refetchDocuments,
    handleCloseUploadFileModal,
  );

  const fund = fundInfo?.fund;

  const handleUploadDocumentOnSubmit = async (formData: FormData) => {
    if (params.assetId) {
      uploadFileMutation({ assetId: params.assetId, form: formData });
    }
  };

  return (
    <>
      <Protected permissions={[Permission.DocumentsAdd, Permission.Documents]}>
        <Button disableElevation onClick={() => handleOpenUploadFileModal()} className="min-width-primary-button">
          <h4>Upload document</h4>
        </Button>
      </Protected>

      <Modal
        open={openUploadFileModal}
        handleClose={handleCloseUploadFileModal}
        className="document-dialog"
        maxWidth="sm"
        loading={isLoading}
      >
        <UploadDocumentContainer
          closeModal={handleCloseUploadFileModal}
          level="asset"
          folderName={`${fund?.name}/${assetDetails?.Title}`}
          handleOnSubmit={handleUploadDocumentOnSubmit}
          id={params.assetId}
        />
      </Modal>
    </>
  );
};
