import { ThemeCssVarOverrides } from '@mui/material';

export const menuItemComponentTheme: ThemeCssVarOverrides = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        border: '1px solid transparent',

        '&.Mui-selected': {
          background: '#f6f5f4',

          '&.Mui-focusVisible': {
            background: '#f6f5f4',
          },

          '&:hover': {
            background: '#f6f5f4',
          },
        },

        '&.Mui-focusVisible': {
          background: '#f6f5f4',
          border: '1px solid #E1E1E1',
        },
      },
    },
  },
};
