import React from 'react';
import Badge, { BadgeStyle } from '../common/Badge';

import styles from './styles.module.scss';
import { Transaction, TransactionCategory } from '../../types/transactions';

interface TransactionCardProps {
  transaction: Transaction;
  onClick?: () => void;
}

const TransactionCard = ({ transaction, onClick = () => {} }: TransactionCardProps) => {
  const getBadgeComponent = () => {
    return (
      <Badge
        badgeStyle={
          transaction.category.toUpperCase() === TransactionCategory.CAPITAL_CALL_BUY_IN
            ? BadgeStyle.BLUE
            : BadgeStyle.YELLOW
        }
        text={transaction.category}
      />
    );
  };

  return (
    <div className={styles.cardContainer} onClick={onClick}>
      <div>
        <label>Transaction ID</label>
        {transaction.transactionId}
      </div>

      <div>{getBadgeComponent()}</div>

      <div>
        <label>Issued</label>
        {transaction.issued}
      </div>

      <div>
        <label>LP name</label>
        {transaction.lpName}
      </div>

      <div>
        <label>Payment deadline</label>
        {transaction.deadline}
      </div>

      <div>
        <label>Amount</label>
        {transaction.amount}
      </div>
    </div>
  );
};

export default TransactionCard;
