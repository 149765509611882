import { Navigate } from 'react-router-dom';
import { Permission } from 'src/consts/permissions';
import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from 'src/hooks/usePermission';

interface ProtectedPageProps {
  children: JSX.Element;
  permissions?: string[];
  allowForLp?: boolean;
}

export const Protected = ({ children, permissions, allowForLp = false }: ProtectedPageProps) => {
  const { userData } = useAuth();
  const { isAdmin, isGp, isLp } = useUserRole();

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  if (isAdmin) {
    const hasPermissions = permissions?.some(permission => userData.featureFlags?.includes(permission as Permission));
    if (hasPermissions) {
      return children;
    }
  }

  if (isLp && allowForLp) {
    return children;
  }

  if (isGp) {
    const hasLicenceKeyAndPermissions =
      permissions?.some(permission => userData.featureFlags?.includes(permission as Permission)) &&
      permissions?.every(permission => userData.permissions?.includes(permission));

    if (hasLicenceKeyAndPermissions) {
      return children;
    }
  }

  return null;
};
