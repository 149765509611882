import { Close } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogProps as MuiDialogProps,
  Stack,
} from '@mui/material';
import { Loader } from '../Loader';
import { ArrowGoBackButton } from 'src/components/common';

export interface ModalProps extends MuiDialogProps {
  title?: string;
  handleClose: () => void;
  closeOnClickOutside?: boolean;
  loading?: boolean;
  withGoBackButton?: boolean;
  withCloseButton?: boolean;
}

export const Modal = ({
  title,
  open,
  onClose,
  handleClose,
  children,
  className,
  fullWidth = true,
  maxWidth = 'sm',
  loading,
  closeOnClickOutside,
  withGoBackButton,
  withCloseButton = true,
  ...props
}: ModalProps) => {
  return (
    <MuiDialog
      className={className}
      open={open}
      onClose={closeOnClickOutside ? onClose : undefined}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      {!loading ? (
        <>
          {title && (
            <Stack direction="column" spacing={1}>
              {withGoBackButton && !withCloseButton && <ArrowGoBackButton onClick={handleClose} />}
              {withGoBackButton && withCloseButton && (
                <Stack direction="row" justifyContent="space-between">
                  <ArrowGoBackButton onClick={handleClose} />
                  <IconButton onClick={handleClose} className="dialog-close-btn">
                    <Close />
                  </IconButton>
                </Stack>
              )}

              <Stack direction="row" justifyContent="space-between">
                <DialogTitle>{title}</DialogTitle>
                {withCloseButton && !withGoBackButton && (
                  <IconButton onClick={handleClose} className="dialog-close-btn">
                    <Close />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}
          <DialogContent sx={{ margin: 0 }}>{children}</DialogContent>
        </>
      ) : (
        <Loader />
      )}
    </MuiDialog>
  );
};
