import { useMemo, useState } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import TransactionCard from 'src/components/transaction-card/TransactionCard';
import CrudComponent from 'src/components/common/CrudComponent';
import { formatDateNoTime, renderCurrency } from '../utils';
import { GpPaymentColumns } from './GpPayments.columns';
import { usePayments } from 'src/hooks/react-query/banking';
import { usePagination } from 'src/hooks/usePagination';
import { Table } from 'src/components/common';
import { Loader } from 'src/components/common/Loader';

type GpPaymentsPendingProps = {
  title: string;
  pendingType: 'Approved' | 'Pending';
};

type SortField = 'issued' | 'amount';

const sortFunctions = {
  issued: (a: string, b: string) => {
    return new Date(a) > new Date(b) ? 1 : -1;
  },
  amount: (a: string, b: string) => {
    const aValue = parseFloat(a.replace(/[$,]/g, ''));
    const bValue = parseFloat(b.replace(/[$,]/g, ''));

    return aValue - bValue;
  },
};

export const GpPayments = ({ title, pendingType }: GpPaymentsPendingProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const state = useLocation()?.state;

  const fundId = params.fundId;
  const fundName = state?.fund_Name ?? '';

  const [sortField, setSortField] = useState<SortField>('issued');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const { page, pageSize, setPageSize, setActivePage } = usePagination();

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const handleChangePage = (newPage: number) => {
    setActivePage(newPage);
  };

  const handleSortChange = (sortField: SortField, sortOrder: 'asc' | 'desc') => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const { data: payments, isLoading: isPaymentsLoading } = usePayments({
    status: pendingType,
    fundId: Number(fundId),
  });

  const paymentsData = useMemo(() => {
    if (payments && Array.isArray(payments) && !isPaymentsLoading) {
      return payments.map(transaction => {
        return {
          id: transaction.id,
          transactionId: transaction.TransactionCode,
          issued: formatDateNoTime(transaction.CreationDateTime),
          category: transaction.category,
          lpName: transaction.Seller?.FirstName,
          amount: renderCurrency(transaction.request.RequestedAmount),
          paymentId: transaction.id,
        };
      });
    }

    return [];
  }, [payments, isPaymentsLoading]);

  const sortedPaymentsData = useMemo(() => {
    if (paymentsData && sortField && sortOrder) {
      return paymentsData.sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        const comparisonResult = sortFunctions[sortField](aValue, bValue);

        return sortOrder === 'asc' ? comparisonResult : -comparisonResult;
      });
    }
    return [];
  }, [paymentsData, sortField, sortOrder]);

  if (!payments || !Array.isArray(payments) || isPaymentsLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="content-wrap">
        <Breadcrumbs className="breadcrumb mb-15">
          {[
            <Link key="GPBanking" color="inherit" to="/gpbanking">
              Banking
            </Link>,
            <Link key={fundName} color="inherit" to={'/gpbanking/' + fundId}>
              {fundName}
            </Link>,
            <Typography key="Review Payments">Pending Payments</Typography>,
          ]}
        </Breadcrumbs>
      </div>

      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>{fundName}</h2>
        </div>
      </div>

      <CrudComponent
        title={title}
        tableLayout={
          <Table
            columns={GpPaymentColumns}
            data={sortedPaymentsData}
            order={sortOrder}
            pagination={{
              page,
              rowsPerPage: pageSize,
              total: payments.length,
              onPageChange: handleChangePage,
              onPageSizeChange: handleChangeRowsPerPage,
            }}
            handleSortChange={handleSortChange}
            orderBy={sortField}
            onRowClick={rowId => {
              navigate(`/manual-payment-status/${rowId}`);
            }}
          />
        }
        cardsLayout={
          <div className={styles.cardsContainer}>
            x
            {paymentsData.map(transaction => (
              <TransactionCard
                key={transaction.transactionId}
                transaction={transaction}
                onClick={() => {
                  navigate(`/manual-payment-status/${transaction.id}`);
                }}
              />
            ))}
          </div>
        }
        filterComponent={
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              height: '100%',
            }}
          >
            <p>Coming soon...</p>
          </div>
        }
      />
    </>
  );
};
