// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import PoppinsRegular from '../../assets/fonts/Poppins-Regular.ttf';
import PoppinsMedium from '../../assets/fonts/Poppins-Medium.ttf';
import PoppinsSemiBold from '../../assets/fonts/Poppins-SemiBold.ttf';
import PoppinsBold from '../../assets/fonts/Poppins-Bold.ttf';

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
      fontWeight: 400,
    },
    {
      src: PoppinsMedium,
      fontWeight: 500,
    },
    {
      src: PoppinsSemiBold,
      fontWeight: 600,
    },
    {
      src: PoppinsBold,
      fontWeight: 700,
    },
  ],
});

interface PaymentSummaryPDFProps {
  fundName?: string;
  fundId?: number;
  amount?: number;
  beneficiaryName?: string;
  deadline?: string;
  beneficiaryBankAddress?: string;
  beneficiaryAddress?: string;
  beneficiaryBankName?: string;
  accountNumber?: string;
  branchCode?: string;
  routingNumber?: string;
  beneficiaryBankId?: string;
}

const PaymentSummaryPDF = ({
  fundName,
  fundId,
  amount,
  beneficiaryName,
  deadline,
  beneficiaryBankAddress,
  beneficiaryAddress,
  beneficiaryBankName,
  accountNumber,
  branchCode,
  routingNumber,
  beneficiaryBankId,
}: PaymentSummaryPDFProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.card}>
        <View style={styles.summaryConceptContainer}>
          <View style={styles.conceptDescriptionWrapper}>
            <Text style={styles.conceptLabel}>FUND</Text>
            <Text style={styles.conceptTitle}>{fundName}</Text>
            <Text style={styles.conceptSubtitle}>{fundId}</Text>
          </View>
          <Text style={styles.conceptAmount}>{amount}</Text>
        </View>

        <View style={styles.separator} />

        <View style={[styles.widgetRowWrapper, { marginTop: 0 }]}>
          <View style={[styles.widgetItem, { marginRight: '10px' }]}>
            <Text style={styles.itemLabel}>Beneficiary name</Text>
            <Text style={styles.itemValue}>{beneficiaryName}</Text>
          </View>
          <View style={[styles.widgetItem, { alignItems: 'flex-end', marginLeft: '10px' }]}>
            <Text style={[styles.itemLabel, { textAlign: 'right' }]}>Deadline</Text>
            <Text style={[styles.itemValue, { textAlign: 'right' }]}>{deadline}</Text>
          </View>
        </View>

        <View style={styles.widgetRowWrapper}>
          <View style={[styles.widgetItem, { marginRight: '10px' }]}>
            <Text style={styles.itemLabel}>Beneficiary bank address</Text>
            <Text style={styles.itemValue}>{beneficiaryBankAddress}</Text>
          </View>
          <View style={[styles.widgetItem, { alignItems: 'flex-end', marginLeft: '10px' }]}>
            <Text style={[styles.itemLabel, { textAlign: 'right' }]}>Beneficiary address</Text>
            <Text style={[styles.itemValue, { textAlign: 'right' }]}>{beneficiaryAddress}</Text>
          </View>
        </View>

        <View style={styles.widgetRowWrapper}>
          <View style={[styles.widgetItem, { marginRight: '10px' }]}>
            <Text style={styles.itemLabel}>Beneficiary bank name</Text>
            <Text style={styles.itemValue}>{beneficiaryBankName}</Text>
          </View>
          <View style={[styles.widgetItem, { alignItems: 'flex-end', marginLeft: '10px' }]}>
            <Text style={[styles.itemLabel, { textAlign: 'right' }]}>Account number</Text>
            <Text style={[styles.itemValue, { textAlign: 'right' }]}>{accountNumber}</Text>
          </View>
        </View>

        <View style={styles.widgetRowWrapper}>
          <View style={[styles.widgetItem, { marginRight: '10px' }]}>
            <Text style={styles.itemLabel}>Branch code</Text>
            <Text style={styles.itemValue}>{branchCode}</Text>
          </View>
          <View style={[styles.widgetItem, { alignItems: 'flex-end', marginLeft: '10px' }]}>
            <Text style={[styles.itemLabel, { textAlign: 'right' }]}>Routing number</Text>
            <Text style={[styles.itemValue, { textAlign: 'right' }]}>{routingNumber}</Text>
          </View>
        </View>

        <View style={styles.widgetRowWrapper}>
          <View style={[styles.widgetItem, { marginRight: '10px' }]}>
            <Text style={styles.itemLabel}>Beneficiary bank id (Swift)</Text>
            <Text style={styles.itemValue}>{beneficiaryBankId}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
  },
  card: {
    borderRadius: '16px',
    borderWidth: '1px',
    borderColor: '#E1E1E1',
    marginHorizontal: '1.75in',
    marginVertical: '0.75in',
    width: '5in',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryConceptContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  conceptDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  conceptLabel: {
    color: '#297B80',
    fontWeight: 600,
    fontSize: '10pt',
    marginBottom: '2px',
  },
  conceptTitle: {
    fontSize: '14pt',
    marginBottom: '4px',
    color: 'black',
    fontWeight: 'bold',
  },
  conceptSubtitle: {
    fontSize: '10pt',
    color: '#b6b6b6',
    fontWeight: 400,
  },
  conceptAmount: {
    fontSize: '14pt',
    color: 'black',
    fontWeight: 600,
  },
  separator: {
    minHeight: '1px',
    maxHeight: '1px',
    backgroundColor: '#E1E1E1',
    flexGrow: 0,
    alignSelf: 'stretch',
    marginVertical: '15px',
  },
  widgetRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
  },
  widgetItem: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
    alignItems: 'flex-start',
  },
  itemLabel: {
    fontSize: '10pt',
    color: '#b6b6b6',
    fontWeight: 400,
    maxWidth: '200px',
  },
  itemValue: {
    fontSize: '12pt',
    fontWeight: 400,
    color: 'black',
    marginTop: '2px',
    maxWidth: '145px',
  },
});

export default PaymentSummaryPDF;
