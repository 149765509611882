import { UpdateBankAccountPayload } from 'src/models/bankingModel';

export const filterBankAccountDetailsForEditForm = (data: Record<string, unknown>) => {
  const {
    Alias,
    AccountHolderName,
    RoutingNumber,
    AccountNumber,
    BeneficiaryName,
    BeneficiaryAddress,
    BeneficiaryBankName,
    BeneficiaryBankAddress,
    BeneficiaryBankIdSwift,
    BranchCode,
    BankAccountType,
    AccountLevel,
    AccountLevelId,
    BankLevelId,
  } = data;

  return {
    Alias,
    AccountHolderName,
    RoutingNumber,
    AccountNumber,
    BeneficiaryName,
    BeneficiaryAddress,
    BeneficiaryBankName,
    BeneficiaryBankAddress,
    BeneficiaryBankIdSwift,
    BranchCode,
    BankAccountType,
    AccountLevel,
    AccountLevelId,
    BankLevelId,
  } as UpdateBankAccountPayload;
};
