import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconDashboard from '../../icons/IconDashboard';
import IconPartnerships from '../../icons/IconPartnership';
import IconDocument from '../../icons/IconDocument';
import { IconAnalytics } from '../../icons/IconAnalytics';
import ExtranceLogo from '../../../assets/images/eXtrance.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconBanking from 'src/uiLibrary/icons/IconBanking';
import { Routes } from 'src/consts/routes';
import IconGovernance from 'src/uiLibrary/icons/IconGovernance';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Permission } from 'src/consts/permissions';
import { Protected } from 'src/components/layouts/Protected/Protected';

//TODO: all other tabs will be addeded in the related tasks
const SidebarComponent = () => {
  return (
    <Box className="sidebar-menu-wrap">
      <div className="m-sidebar">
        <div className="sidebar-logo">
          <img alt="logo" src={ExtranceLogo} />
        </div>
        <div className="sidebar-menu-scroll">
          <PerfectScrollbar>
            <List className="sidebar-menu-list">
              <Protected permissions={[Permission.Dashboard]} allowForLp>
                <NavLink to={Routes.dashboard}>
                  <ListItem button>
                    <ListItemIcon>
                      <IconDashboard />
                    </ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>
              <Protected permissions={[Permission.MyFunds]} allowForLp>
                <NavLink to={Routes.myFunds}>
                  <ListItem button>
                    <ListItemIcon>
                      <IconPartnerships />
                    </ListItemIcon>
                    <ListItemText>My Funds</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>

              {process.env.REACT_APP_V1_NAV !== 'true' && (
                <Protected permissions={[Permission.Governance]}>
                  <NavLink to={Routes.governance}>
                    <ListItem button>
                      <ListItemIcon>
                        <IconGovernance />
                      </ListItemIcon>
                      <ListItemText>Governance</ListItemText>
                    </ListItem>
                  </NavLink>
                </Protected>
              )}

              {/* {privileges?.find(privilege => privilege?.Slug === 'marketplace') && (
                <NavLink to="/marketplace">
                  <ListItem button>
                    <ListItemIcon>
                      <IconInvestment />
                    </ListItemIcon>
                    <ListItemText>Marketplace</ListItemText>
                  </ListItem>
                </NavLink>
              )} */}
              {/* TODO: is this the privilege name for this screen? */}
              {/* {privileges?.find((privilege) => privilege?.Slug === "banking") && (
                <NavLink to="/treasury-operations">
                  <ListItem button>
                    <ListItemIcon>
                      <IconBanking />
                    </ListItemIcon>
                    <ListItemText>Treasury Operations</ListItemText>
                  </ListItem>
                </NavLink>
              )} */}
              {/* {!isUserGP && privileges?.find(privilege => privilege?.Slug === 'banking') && (
                <NavLink to="/banking">
                  <ListItem button>
                    <ListItemIcon>
                      <IconBanking />
                    </ListItemIcon>
                    <ListItemText>Banking</ListItemText>
                  </ListItem>
                </NavLink>
              )} */}

              <Protected permissions={[Permission.Banking]}>
                <NavLink to="/gpbanking">
                  <ListItem button>
                    <ListItemIcon>
                      <IconBanking />
                    </ListItemIcon>
                    <ListItemText>Banking</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>

              <Protected allowForLp>
                <NavLink to="/banking">
                  <ListItem button>
                    <ListItemIcon>
                      <IconBanking />
                    </ListItemIcon>
                    <ListItemText>Banking</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>

              <Protected permissions={[Permission.Documents]} allowForLp>
                <NavLink to={Routes.documents}>
                  <ListItem button>
                    <ListItemIcon>
                      <IconDocument />
                    </ListItemIcon>
                    <ListItemText>Documents</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>

              <Protected permissions={[Permission.Analytics]}>
                <NavLink to={Routes.analytics}>
                  <ListItem button>
                    <ListItemIcon>
                      <IconAnalytics />
                    </ListItemIcon>
                    <ListItemText>Analytics</ListItemText>
                  </ListItem>
                </NavLink>
              </Protected>

              {/* {privileges?.find(privilege => privilege?.Slug == 'user-management') && (
                <NavLink to="/users">
                  <ListItem button>
                    <ListItemIcon>
                      <IconUserManagement />
                    </ListItemIcon>
                    <ListItemText>Admin Users Management</ListItemText>
                  </ListItem>
                </NavLink>
              )} */}
            </List>
          </PerfectScrollbar>
        </div>
        <div className="copy-right-leftbar">
          <div className="copy-right">
            <p>© 2024 EXtrance Inc.</p>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default SidebarComponent;
