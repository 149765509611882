import { useParams } from 'react-router-dom';
import { useBankingFundDetails } from 'src/hooks/react-query/banking';
import { GpBankingFundViewComponent } from './gpBankingFundViewComponent';
import { Loader } from 'src/components/common/Loader';
import { FundViewFund } from './gpBankingFundViewComponent';

export const GpBankingContainer = () => {
  const { fundId } = useParams();
  const { data: fundData, isLoading: isFundLoading } = useBankingFundDetails({
    fundId: Number(fundId),
  });

  if (!fundData || isFundLoading) {
    return <Loader />;
  }

  return <GpBankingFundViewComponent fund={fundData as FundViewFund} />;
};
