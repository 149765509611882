import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { createUnitDocument } from 'src/services/documentsService';
import { ApiError } from 'src/types/api';
import { QueryKey } from 'src/consts/query';

export const useUploadUnitDocument = (...callback: Array<() => void>) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ assetId, form }: { assetId: string; form: FormData }) => createUnitDocument(assetId, form),
    onSuccess: responce => {
      showSuccessSnackbar(`Document ${responce.data.Title} has been successfully uploaded`);
      queryClient.invalidateQueries({ queryKey: [QueryKey.Documents] });
      callback.forEach(cb => cb());
    },
    onError: e => {
      console.log('useUploadAssetDocument error', e);
      showErrorSnackbar((e as AxiosError<ApiError>).response?.data.message);
    },
  });
};
