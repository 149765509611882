import { useParams } from 'react-router-dom';
import { useFundBankDetails } from 'src/hooks/react-query/banking';
import { BankAccountCreationForm } from './BankAccountCreation.form';
import { CreateBankAccountPayload, UpdateBankAccountPayload } from 'src/models/bankingModel';

type BankAccountCreationProps = {
  onSubmit: (data: UpdateBankAccountPayload) => void;
  defaultValues?: UpdateBankAccountPayload;
  isSubmitting?: boolean;
};

export const BankAccountCreation = ({ onSubmit, defaultValues, isSubmitting }: BankAccountCreationProps) => {
  const { fundId } = useParams();
  const { data: bankDetails } = useFundBankDetails({
    fundId: Number(fundId),
    enabled: !!fundId,
  });

  const existingBankingAccounts = Array.isArray(bankDetails) ? bankDetails.map(account => account.AccountType) : [];

  const defaultValuesEmpty: CreateBankAccountPayload = {
    Alias: '',
    AccountHolderName: '',
    RoutingNumber: '',
    AccountNumber: '',
    BeneficiaryName: '',
    BeneficiaryAddress: '',
    BeneficiaryBankName: '',
    BeneficiaryBankAddress: '',
    BeneficiaryBankIdSwift: '',
    BranchCode: '',
    BankAccountType: existingBankingAccounts.includes('checking') ? 'savings' : 'checking',
    AccountLevel: 'Fund',
    AccountLevelId: 0,
    BankLevelId: Number(fundId),
  };

  return (
    <BankAccountCreationForm
      defaultValues={defaultValues ? defaultValues : defaultValuesEmpty}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      existingBankingAccounts={existingBankingAccounts}
    />
  );
};
