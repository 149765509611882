import { useNavigate } from 'react-router-dom';
import { IconButton, Grid } from '@mui/material';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { ActiveCapcall } from './components/ActiveCapcall';
import { CashKPIDetails } from './components/CashKPI';
import { GovernanceActionsDetails } from './components/GovernanceActions';
import { CapitalCallHistory } from './components/CapitalCallHistory';
import { CapitalDistHistory } from './components/CapitalDistHistory';
import { CapitalStructure } from './components/CapitalStructure';
import { KPI } from './components/KPI';
// import { Waterfall } from './components/Waterfall';
import { LpListTable } from './components/LpListTable';
import { Protected } from 'src/components/layouts/Protected/Protected';
import { Permission } from 'src/consts/permissions';

export interface GovernanceFundDetailComponentProps {
  fundName?: string;
}

const GovernanceFundDetailComponent = ({ fundName }: GovernanceFundDetailComponentProps) => {
  const navigate = useNavigate();

  const backToFundSelectorHandler = () => {
    navigate('/governance');
  };

  return (
    <>
      <div className="governance-detail-header">
        <div className="back-fund-wrap ml" onClick={backToFundSelectorHandler}>
          <IconButton size="large" component="span">
            <IconArrowLeft stroke="#8F8F8F" />
          </IconButton>
          <p className="back-text">Back to fund selection </p>
        </div>
        <h3>{fundName}</h3>
      </div>
      <div className="governance-detail-container">
        <div className="actions-row">
          <CashKPIDetails hasEtbTooltip />

          <Protected permissions={[Permission.Governance]}>
            <GovernanceActionsDetails />
          </Protected>
        </div>
        <div className="governance-detail-row-container">
          <CapitalStructure />
          <div className="capital-actions-history-col">
            <CapitalCallHistory />
            <CapitalDistHistory />
          </div>
        </div>
        <div className="governance-detail-row-container">
          <ActiveCapcall fundName={fundName} />
        </div>
      </div>
      <KPI fundName={fundName} />

      {/* <Waterfall /> */}

      <div className="table-row list-lps">
        <Grid item xs={12} className="title-and-action-buttons">
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <h2>List of LP&apos;s</h2>
            </Grid>
            <Grid item xs={10}></Grid>
          </Grid>
        </Grid>
        <LpListTable />
      </div>
    </>
  );
};

export default GovernanceFundDetailComponent;
