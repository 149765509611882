import { manualPaymentTransactionType } from 'src/models/manualPaymentModels';
import styles from './styles.module.scss';

interface TransactionDetailsWidgetProps {
  transactionDetails: manualPaymentTransactionType;
}

const TransactionDetailsWidget = ({ transactionDetails }: TransactionDetailsWidgetProps) => {
  return (
    <div className={styles.transactionDetailsWidget}>
      <div className={styles.widgetHeaderWrapper}>
        <div className={styles.widgetTitle}>Details</div>
        <div className={styles.widgetSubtitle}>Details about transaction</div>
      </div>

      <div className={styles.separator} />

      <div className={styles.widgetItem}>
        <div className={styles.itemLabel}>Category</div>
        <div className={styles.itemValue}>
          {transactionDetails.category === 'capitalCallBuyIn'
            ? 'Capital call buy in'
            : transactionDetails.category === 'capitalCallFunding'
              ? 'Capital call funding'
              : transactionDetails.category === 'positionSellout'
                ? 'Position sellout'
                : transactionDetails.category === 'distributionPayment'
                  ? 'Distribution'
                  : ''}
        </div>
      </div>
      <div className={styles.widgetItem}>
        <div className={styles.itemLabel}>Fund</div>
        <div className={styles.itemValue}>{transactionDetails.fund || transactionDetails.fundName}</div>
      </div>
      <div className={styles.widgetItem}>
        <div className={styles.itemLabel}>Date of issue</div>
        <div className={styles.itemValue}>{transactionDetails.date}</div>
      </div>
      <div className={styles.widgetItem}>
        <div className={styles.itemLabel}>Transaction ID</div>
        <div className={styles.itemValue}>{transactionDetails.id}</div>
      </div>
    </div>
  );
};

export default TransactionDetailsWidget;
