import { createContext, useContext, ReactNode } from 'react';

type GpBankingPreselectionContextValue = {
  selectedFundId?: number;
  setSelectedFundId?: (fundId: number) => void;
};

const GpBankingPreselectionContext = createContext<GpBankingPreselectionContextValue>({});

export const useGpBankingPreselectionContext = () => useContext(GpBankingPreselectionContext);

export const GpBankingPreselectionContextProvider = ({
  value,
  children,
}: {
  value: GpBankingPreselectionContextValue;
  children: ReactNode;
}) => <GpBankingPreselectionContext.Provider value={value}>{children}</GpBankingPreselectionContext.Provider>;
