import { validateError } from 'src/utils/validateError';
import ServiceClient from './serviceClientConfig';

enum PaymentType {
  Manual = 'Manual',
}

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getPayments = async (
  status?: string,
  fundId?: number,
  typeStatus?: string,
  transactionStatus?: string,
) => {
  try {
    let url = `payment?`;
    if (fundId) {
      url = url + `fundId=${fundId}`;
    }
    if (status) {
      url = fundId ? url + `&filter_status=${status}` : url + `filter_status=${status}`;
    }
    if (typeStatus) {
      url =
        fundId || status
          ? url + `&filter_transaction_type=${typeStatus}`
          : url + `filter_transaction_type=${typeStatus}`;
    }
    if (transactionStatus) {
      url =
        fundId || status || typeStatus
          ? url + `&filter_transaction_status=${transactionStatus}`
          : url + `filter_transaction_status=${transactionStatus}`;
    }

    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getPayments error: ', error);
    throw await validateError(error);
  }
};

export const getFundRequestedUsers = async () => {
  // return await serviceClient.get(url);
  const url = `fund-users/order-page?&filter_status=Reviewing`;
  try {
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('getFundRequestedUsers error: ', error);
    throw await validateError(error);
  }
};
export const getFundRequestedUsersForFund = async (fundId: number) => {
  const url = `fund-users/order-page/fund/${fundId}?filter_type=Cap&filter_status=Reviewing`;
  return await serviceClient.get(url);
};

export const getFundRequest = async (id: string) => {
  const url = `fund-users/order-page/${id}`;
  return await serviceClient.get(url);
};

export const generatePayment = async (requestId: number) => {
  try {
    const response = await serviceClient.post(`payment/generate-payment/${requestId}`, {
      method: 'POST',
      body: JSON.stringify(null),
    });
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as any;
  } catch (error) {
    console.error('generatePayment error: ', error);
    throw await validateError(error);
  }
};

export const generateAutomaticPayment = async (requestId: number, bankId: number) => {
  const url = `payment/generate-automatic-payment/${requestId}`;
  const payload = {
    bankId,
  };
  return await serviceClient.post(url, payload);
};

export const markPaymentAsReceived = async (paymentId: number, amount: number) => {
  const url = `payment/receive/${paymentId}`;
  const payload = {
    PaymentType: PaymentType.Manual,
    PaymentAmount: amount,
  };
  return await serviceClient.post(url, payload);
};

export const markPaymentAsSent = async (paymentId: number, amount: number) => {
  const url = `payment/send/${paymentId}`;
  const payload = {
    PaymentAmount: amount,
  };
  return await serviceClient.post(url, payload);
};

export const getManualPayment = async (id: number) => {
  const url = `payment/manual/${id}`;
  return await serviceClient.get(url);
};

export const addAttachedFile = async (id: number, file: File) => {
  const customServiceClient = new ServiceClient({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const formData = new FormData();
  formData.append('receiptFile', file);
  const url = `payment/receipt/${id}`;
  return await customServiceClient.post(url, formData);
};

export const getAttachedFile = async (id: string) => {
  const url = `/payment/get-receipt/${id}`;
  return await serviceClient.get(url);
};

export type GetLpPendingPaymentsProps = {
  userId: number;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
};

export const getLpPendingPayments = async ({
  userId,
  page = 1,
  pageSize = 10,
  sortBy,
  sortOrder,
}: GetLpPendingPaymentsProps) => {
  try {
    const url = `payment/lp-pending-payments`;

    const params: GetLpPendingPaymentsProps = {
      userId,
    };

    if (page) {
      params.page = page;
    }

    if (pageSize) {
      params.pageSize = pageSize;
    }

    if (sortBy) {
      params.sortBy = sortBy;
    }

    if (sortOrder) {
      params.sortOrder = sortOrder;
    }

    const response = await serviceClient.get(url, { params });
    return response.data;
  } catch (error) {
    console.error('Get Lp pending payments error: ', error);
    throw await validateError(error);
  }
};
