import { formatDate } from 'src/views/utils';
import { renderCurrency } from 'src/views/utils';
import { renderCategoryBadge } from 'src/utils/renderBadge';
import { LpPayment } from 'src/types/payments';
import IconArrowRight from 'src/uiLibrary/icons/IconArrowRight';
import { HeadCellItem } from 'src/models/tableModels';
import { Button } from 'src/components/common';

type GetPendingPaymentColumnsProps = {
  payNowOnClick: (paymentId: number) => void;
  showDetailsOnClick: (paymentId: number) => void;
};

export const getPendingPaymentColumns = ({
  payNowOnClick,
  showDetailsOnClick,
}: GetPendingPaymentColumnsProps): HeadCellItem<LpPayment>[] => [
  {
    id: 'issued',
    label: 'Issued',
    align: 'left',
    disablePadding: false,
    sortable: true,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => formatDate(String(payment.issued)),
  },
  {
    id: 'fundName',
    label: 'Fund Name',
    align: 'left',
    disablePadding: false,
    sortable: false,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => payment.fundName ?? 'No information',
  },
  {
    id: 'receiver',
    label: 'Receiver',
    align: 'left',
    disablePadding: false,
    sortable: false,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => payment.receiver,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    disablePadding: false,
    sortable: false,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => renderCategoryBadge(payment.category),
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'left',
    disablePadding: false,
    sortable: true,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => renderCurrency(payment.amount),
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    disablePadding: false,
    sortable: false,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => {
      if (payment.status === 'Pending') {
        return (
          <Button order="secondary" size="small" onClick={() => payNowOnClick(payment.id)}>
            Pay Now
            <IconArrowRight />
          </Button>
        );
      }
      return (
        <Button order="secondary" size="small" onClick={() => showDetailsOnClick(payment.id)}>
          Details
          <IconArrowRight />
        </Button>
      );
    },
  },
  {
    id: 'transactionCode',
    label: 'Transaction ID',
    align: 'left',
    disablePadding: false,
    sortable: false,
    whiteSpace: 'nowrap',
    render: (payment: LpPayment) => payment.transactionCode,
  },
];
