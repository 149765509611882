import { ThemeCssVarOverrides } from '@mui/material';

export const formLabelComponentsTheme: ThemeCssVarOverrides = {
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22.4px',
        color: '#8f8f8f',
        marginBottom: '5px',

        '&.Mui-focused': {
          color: '#8f8f8f',
        },

        '&.Mui-required': {
          '.MuiFormLabel-asterisk': {
            color: '#FF5953',
          },
        },
      },
    },
  },
};
