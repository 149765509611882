import { Breadcrumbs, Typography } from '@mui/material';
import { DocumentData } from 'src/components/documents/document-card/document-card-helpers';
import { Link, useParams } from 'react-router-dom';
import { AssetById } from 'src/types/Assets';
import { FoldersAndDocuments } from 'src/components/documents/FoldersAndDocuments/FoldersAndDocuments';
import { Folder } from 'src/types/Documents';
import { UploadDocument } from './components/UploadDocument';

interface DocumentsAssetDetailComponentProps {
  fund: {
    id?: number;
    name: string;
  };
  asset?: AssetById;
  documents: DocumentData[];
  folders: Folder[];
  openCreateFolderModal: () => void;
  openUpdateFolderModal: (folderId: number, oldFolderName: string) => void;
  handleDeleteOnClick: (documentId: number, documentName: string) => Promise<void>;
}

export const DocumentsUnitDetailComponent = ({
  fund,
  asset,
  documents,
  folders,
  openCreateFolderModal,
  openUpdateFolderModal,
  handleDeleteOnClick,
}: DocumentsAssetDetailComponentProps) => {
  const params = useParams();
  const unitId = params.unitId;

  return (
    <>
      <Breadcrumbs className="breadcrumb mb-15">
        {[
          <Link key="documents" color="inherit" to="/documents">
            Documents
          </Link>,
          <Link key="fund" color="inherit" to={`/documents/fund/${fund?.id}`}>
            {fund?.name}
          </Link>,
          <Link key="fund" color="inherit" to={`/documents/fund/${fund?.id}/asset/${asset?.id}`}>
            {asset?.Title}
          </Link>,
          <Typography key="unit">Units</Typography>,
        ]}
      </Breadcrumbs>
      <div className="d-flex">
        <div className="title-and-subtitle-section">
          <h2>Units</h2>
          <h4>{documents.length} Unit Level Documents</h4>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <UploadDocument />
        </div>
      </div>

      <FoldersAndDocuments
        documents={documents}
        folders={folders}
        isDisabled={!unitId}
        handleAddFolderButtonClick={openCreateFolderModal}
        handleEditFolderButtonClick={openUpdateFolderModal}
        handleDeleteDocumentClick={handleDeleteOnClick}
      />
    </>
  );
};
