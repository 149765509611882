import cn from 'classnames';
import styles from './styles.module.scss';

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  customClasses?: string;
  customStyle?: { [k: string]: string | number };
  size?: 'small' | 'medium' | 'large';
  order?: 'primary' | 'secondary';
  disabled?: boolean;
  children?: React.ReactNode;
  tooltipContent?: React.ReactNode;
  tooltipStyle?: { [k: string]: string | number };
  variant?: 'primary' | 'secondary';
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

const Button = ({
  onClick,
  children = 'Continue',
  customClasses,
  customStyle,
  size,
  order,
  disabled = false,
  tooltipStyle,
  tooltipContent,
  variant = 'primary',
  fullWidth,
  type = 'button',
}: ButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type}
      style={customStyle}
      className={cn(
        styles.button,
        {
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.sizeSmall]: size === 'small',
          [styles.sizeMedium]: size === 'medium',
          [styles.sizeLarge]: size === 'large',
          [styles.orderPrimary]: order === 'primary',
          [styles.orderSecondary]: order === 'secondary',
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled,
        },
        customClasses,
      )}
      onClick={handleClick}
    >
      {tooltipContent && (
        <div className={styles['button-tooltip']} style={tooltipStyle}>
          {tooltipContent}
        </div>
      )}
      {children}
    </button>
  );
};
export default Button;
